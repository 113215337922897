export const UAH_IE = 'UAH_IE';
export const UAH_NP = 'UAH_NP';
export const USD = 'USD';

const LIST = [
  UAH_IE,
  UAH_NP,
  USD,
];

export default LIST;
