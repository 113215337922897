export const EUR = 'EUR';
export const PLN = 'PLN';
export const UAH = 'UAH';
export const USD = 'USD';
export const PERCENT = '%';

const availableTaxMeasures = [
  PERCENT,
  UAH,
  USD,
  EUR,
  PLN,
];

export default availableTaxMeasures;
