import {
  ERROR_RECEIVE_TAX_TYPES,
  RECEIVE_TAX_TYPES,
  REQUEST_TAX_TYPES,
  RESET_REDUCERS_DATA,
} from '../constants/actionTypes';

type Action = {
  payload: any,
  type: string,
}

const initialState = {
  isFailed: false,
  isFetching: false,
  list: [],
};

const convertTaxTypes = (taxTypes: any) => (taxTypes || [])
  .map((type: any) => ({
    id: type.id,
    name: type.name,
  }));

export default function Reducer(state = initialState, action: Action) {
  switch (action.type) {
    case REQUEST_TAX_TYPES: {
      return {
        ...state,
        isFailed: false,
        isFetching: true,
      };
    }

    case RECEIVE_TAX_TYPES: {
      const taxTypes = action.payload;
      const list = convertTaxTypes(taxTypes);

      return {
        ...state,
        isFailed: false,
        isFetching: false,
        list: list,
      };
    }

    case ERROR_RECEIVE_TAX_TYPES: {
      return {
        ...state,
        isFailed: true,
        isFetching: false,
      };
    }

    case RESET_REDUCERS_DATA: return initialState;

    default: return state;
  }
}
