import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { makeStyles } from 'tss-react/mui';
import { useIntl } from 'react-intl';
import Breadcrumb from 'components/Breadcrumb';
import Breadcrumbs from 'components/Breadcrumbs';
import Button from 'components/Button';
import Card from 'components/Card';
import CardActions from 'components/CardActions';
import CardContent from 'components/CardContent';
import CardTitle from 'components/CardTitle';
import classNames from 'classnames';
import IconButton from 'components/IconButton';
import IconClose from 'components/icons/Close';
import IconEdit from 'components/icons/Edit';
import Loading from 'components/Loading';
import MenuItem from 'components/MenuItem';
import Select from 'components/Select';
import Snackbar from 'components/Snackbar';
import TextField from 'components/TextField';
import ToolTip from 'components/Tooltip';
import Typography from 'components/Typography';
import useTheme from 'hooks/useTheme';

import * as pages from 'constants/pages';
import pagesURLs from 'constants/pagesURLs';
import actionTax from '../actions/taxes';
import actionTaxTypes from '../actions/taxTypes';

const getClasses = makeStyles<any>()((_, theme: any) => ({
  cellMeasureTypeContainer: {
    background: theme.card.background.paper,
    border: `1px solid ${theme.colors.greyLight}`,
    boxSizing: 'border-box',
    height: '40px',
    maxWidth: '60px',
    minWidth: '60px',
    padding: `0px ${theme.spacing(0.5)}px`,
  },
  cellMonth: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
  cellMonthContainer: {
    background: theme.card.background.paper,
    border: `1px solid ${theme.colors.greyLight}`,
    boxSizing: 'border-box',
    flex: 1,
    height: '40px',
    maxWidth: '80px',
    minWidth: '0px',
    padding: `0px ${theme.spacing(0.5)}px`,
  },
  cellTax: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
  cellTaxTypeContainer: {
    background: theme.card.background.paper,
    border: `1px solid ${theme.colors.greyLight}`,
    boxSizing: 'border-box',
    height: '40px',
    maxWidth: '100px',
    minWidth: '100px',
    padding: `0px ${theme.spacing(0.5)}px`,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(2)}px`,
  },
  editable: {
    background: `${theme.card.background.edit} !important`,
  },
  hovered: {
    background: `${theme.hover.background} !important`,
    cursor: 'pointer',
  },
  row: {
    display: 'flex',
  },
  rows: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '900px',
  },
  selectYear: {
    width: '80px',
  },
  toolTipContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '80px',
  },
}));

const totalMonthsCount = 12;

const currentMonth = (new Date()).getMonth() + 1;
const currentYear = (new Date()).getFullYear();

const availableOrderedYears = [
  currentYear + 1,
  currentYear,
  ...Array
    .from(new Array(3).keys())
    .map(index => currentYear - (index + 1)),
];

const groupTaxes = (list: any) => list
  .reduce((acc: any, tax: any) => {
    acc[tax.year] ={
      ...(acc[tax.year] || {}),
      [tax.month]: {
        ...(acc[tax.year]?.[tax.month] || {}),
        [tax.taxTypeId]: {
          value: tax.value,
        },
      },
    };
    return acc;
  }, {} as any);

const formatNumber = (value: any) => value
  ? value.toString().replace('.', ',')
  : value;

function Taxes() {
  const { theme } = useTheme();
  const { classes } = getClasses(theme);
  const { formatMessage } = useIntl();
  const dispatch: Dispatch<any> = useDispatch();
  const componentDidMount = useRef(false);

  const {
    isFailedSave,
    isFailed: isFailedTaxes,
    isFetching: isFetchingTaxes,
    isFetchingSave,
    list: taxesList,
  } = useSelector(({
    tax: reducerTax,
  }: any) => reducerTax);

  const {
    isFailed: isFailedTaxTypes,
    isFetching: isFetchingTaxTypes,
    list: taxTypesList,
  } = useSelector(({
    taxTypes: reducerTaxType,
  }: any) => reducerTaxType);

  const [state, setState]: any = useState({
    editableItems: [],
    groupedTaxes: {},
    hover: {
      month: null,
      taxTypeId: null,
    },
    isEditingMode: false,
    selectedYear: currentYear,
    showAfterSaveAlter: false,
    taxes: taxesList,
  });

  const actualTypesList = useMemo(() => {
    return taxTypesList.filter((type: any) => !!type.active);
  }, [taxTypesList]);

  const isFailedContent = isFailedTaxes || isFailedTaxTypes;

  const isFetchingContent = isFetchingTaxes || isFetchingTaxTypes;

  const usedOrderedMonths = Array
    .from(new Array(totalMonthsCount).keys())
    .map(key => key + 1);

  const isEditable = ({
    month,
    taxTypeId,
    year,
  }: any) => state.editableItems
    .some((item: any) =>
      item.year === year
      && item.month === month
      && item.taxTypeId === taxTypeId);

  const getValue = ({
    month,
    taxTypeId,
    year,
  }: any) => {
    const foundEditableItem = state.editableItems
      .find((item: any) =>
        item.year === year
        && item.month === month
        && item.taxTypeId === taxTypeId
      );
    return foundEditableItem
      ? foundEditableItem.value
      : state.groupedTaxes[year]
        ?.[month]
        ?.[taxTypeId]
        ?.value;
  };

  const onValueClick = ({
    month,
    taxTypeId,
    value,
    year,
  }: any) => {
    if (
      !isEditable({
        month,
        taxTypeId,
        year,
      })
    ) {
      const editableItems = state.editableItems.concat({
        month,
        taxTypeId,
        value,
        year,
      });
      setState({
        ...state,
        editableItems,
        isEditingMode: true,
      });
    }
  };

  const onValueChange = ({
    month,
    taxTypeId,
    value,
    year,
  }: any) => {
    setState({
      ...state,
      editableItems: state.editableItems
        .map((item: any) => (
          item.year === year
          && item.month === month
          && item.taxTypeId === taxTypeId)
          ? {
            ...item,
            value,
          } : item
        ),
    });
  };

  const onValueBlur = ({
    month,
    taxTypeId,
    year,
  }: any) => {
    const oldValue = state.groupedTaxes[year]?.[month]?.[taxTypeId]?.value;
    const newValue = state.editableItems
      .find((item: any) => item.year === year
        && item.month === month
        && item.taxTypeId === taxTypeId
      )?.value;
    if ((!oldValue && !newValue)
      || (oldValue === newValue)) {
      setState({
        ...state,
        editableItems: state.editableItems
          .filter((item: any) => item.year !== year
            || item.month !== month
            || item.taxTypeId !== taxTypeId),
      });
    }
  };

  const onCancelEdit = () => {
    setState({
      ...state,
      editableItems: [],
      isEditingMode: false,
    });
  };

  const onSave = () => {
    const groupedSaveList = state.editableItems
      .reduce((acc: any, item: any) => ({
        ...acc,
        [item.year]: {
          ...(acc[item.year] || {}),
          [item.taxTypeId]: {
            ...(acc[item.year]?.[item.taxTypeId] || {}),
            [item.month]: item.value,
          },
        },
      }), {});
    const saveList = Object
      .entries(groupedSaveList)
      .reduce((acc: any, [year, monthsToTaxesObj]: any) => {
        Object
          .entries(monthsToTaxesObj)
          .forEach(([taxTypeId, valuesToMonthsObj]: any) => {
            const saveTaxes = Object
              .entries(valuesToMonthsObj)
              .map(([month, value]: any) => ({
                month: +month,
                value: value,
              }));
            acc.push({
              saveTaxes,
              taxTypeId,
              year: +year,
            });
          });
        return acc;
      }, []);
    dispatch(actionTax.fetchSaveTax({
      saveList,
      year: state.selectedYear,
    }));
  };

  const onCloseAlert = () => setState((prevState: any) => ({
    ...prevState,
    showAfterSaveAlert: false,
  }));

  useEffect(() => {
    if (taxesList) {
      const groupedTaxes = groupTaxes(taxesList);
      setState((prevState: any) => ({
        ...prevState,
        groupedTaxes,
      }));
    }
  }, [taxesList]);

  useEffect(() => {
    dispatch(actionTax.fetchTaxes(state.selectedYear));
  }, [state.selectedYear]);

  useEffect(() => {
    if (componentDidMount.current && !isFetchingSave) {
      setState((prevState: any) => ({
        ...prevState,
        editableItems: isFailedSave ? state.editableItems : [],
        isEditingMode: isFailedSave,
        showAfterSaveAlert: true,
      }));
    }
  }, [isFailedSave, isFetchingSave]);

  useEffect(() => {
    componentDidMount.current = true;
    dispatch(actionTaxTypes.fetchTypes());
  }, []);

  return (
    <div className={classes.container}>
      <Breadcrumbs>
        <Breadcrumb
          label={formatMessage({ id: 'settings' })}
          to={{
            pathname: `${pagesURLs[pages.settings]}`,
          }}
          variant="link"
        />
        <Breadcrumb
          label={formatMessage({ id: 'taxes' })}
          variant="text"
        />
      </Breadcrumbs>
      <div className={classes.selectYear}>
        <Select
          fullWidth
          onChange={({ target }) => setState({
            ...state,
            editableItems: [],
            isEditingMode: false,
            selectedYear: target.value,
          })}
          size="small"
          value={state.selectedYear}
          variant="outlined"
        >
          {availableOrderedYears.map(year => (
            <MenuItem value={year}>
              <Typography>
                {year}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </div>
      {(isFetchingContent || isFailedContent) && (
        <Loading variant={isFailedContent ? 'error' : 'loading'}>
          {isFailedContent && (
            <Typography
              color="secondary"
              variant="subtitle"
            >
              {formatMessage({ id: 'loading.error' })}
            </Typography>
          )}
        </Loading>
      )}
      {!isFetchingContent && !isFailedContent && (
        <Card
          variant={state.isEditingMode && 'edit'}
        >
          <CardTitle>
            <Typography
              color="secondary"
              variant="subtitle"
            >
              {formatMessage(
                { id: 'title' },
                { value: state.selectedYear }
              )}
            </Typography>
            {(state.isEditingMode) && (
              <IconButton
                disableHoverSpace
                onClick={onCancelEdit}
              >
                <IconClose />
              </IconButton>
            )}
            {!state.isEditingMode && (
              <IconButton
                disableHoverSpace
                onClick={() => setState({
                  ...state,
                  isEditingMode: true,
                })}
              >
                <IconEdit size={20} />
              </IconButton>
            )}
          </CardTitle>
          <CardContent>
            <div className={classes.rows}>
              <div className={classes.row}>
                <div className={classes.cellTaxTypeContainer}>
                  <div className={classes.cellTax}>
                    <Typography
                      color="secondary"
                      variant="caption"
                    >
                      {formatMessage({ id: 'typeTax' })}
                    </Typography>
                  </div>
                </div>
                {usedOrderedMonths.map((month: any) => (
                  <div
                    className={classNames(
                      classes.cellMonthContainer,
                      month === state.hover.month
                      && classes.hovered
                    )}
                  >
                    <div className={classes.cellMonth}>
                      {month === currentMonth &&
                        state.selectedYear === currentYear && (
                        <Typography
                          variant="caption"
                        >
                          <strong>
                            {formatMessage({ id: `month.${month}` })}
                          </strong>
                        </Typography>
                      )}
                      {(month !== currentMonth
                          || state.selectedYear !== currentYear
                      ) && (
                        <Typography
                          color="secondary"
                          variant="caption"
                        >
                          {formatMessage({ id: `month.${month}` })}
                        </Typography>
                      )}
                    </div>
                  </div>
                ))}
                <div className={classes.cellMeasureTypeContainer}>
                  <div className={classes.cellTax}>
                    <Typography
                      color="secondary"
                      variant="caption"
                    >
                      {formatMessage({ id: 'measureTypeTax' })}
                    </Typography>
                  </div>
                </div>
              </div>
              {actualTypesList.map((taxItem: any) => (
                <div className={classes.row}>
                  <div
                    className={classNames(
                      classes.cellTaxTypeContainer,
                      taxItem.id === state.hover.taxTypeId
                      && classes.hovered
                    )}
                  >
                    <div className={classes.cellTax}>
                      <ToolTip title={taxItem.name}>
                        <div className={classes.toolTipContainer}>
                          <Typography
                            color="secondary"
                            noWrap
                          >
                            {taxItem.name}
                          </Typography>
                        </div>
                      </ToolTip>
                    </div>
                  </div>
                  {usedOrderedMonths.map(month => (
                    <div
                      className={classNames(
                        classes.cellMonthContainer,
                        month === state.hover.month
                          && taxItem.id === state.hover.taxTypeId
                          && !isEditable({
                            month,
                            taxTypeId: taxItem.id,
                            year: state.selectedYear,
                          })
                          && classes.hovered,
                        isEditable({
                          month,
                          taxTypeId: taxItem.id,
                          year: state.selectedYear,
                        }) && classes.editable
                      )}
                      onClick={() => onValueClick({
                        month,
                        taxTypeId: taxItem.id,
                        value: state.groupedTaxes[state.selectedYear]
                          ?.[month]
                          ?.[taxItem.id]?.value,
                        year: state.selectedYear,
                      })}
                      onMouseEnter={() => setState({
                        ...state,
                        hover: {
                          ...state.hover,
                          month,
                          taxTypeId: taxItem.id,
                        },
                      })}
                      onMouseLeave={() => setState({
                        ...state,
                        hover: {
                          ...state.hover,
                          month: null,
                          taxTypeId: null,
                        },
                      })}
                    >
                      <div className={classes.cellMonth}>
                        {isEditable({
                          month,
                          taxTypeId: taxItem.id,
                          year: state.selectedYear,
                        }) ? (
                            <TextField
                              autoFocus
                              onBlur={() => onValueBlur({
                                month,
                                taxTypeId: taxItem.id,
                                year: state.selectedYear,
                              })}
                              onChange={({ target }) => onValueChange({
                                month,
                                taxTypeId: taxItem.id,
                                value: target.value,
                                year: state.selectedYear,
                              })}
                              inputType="number"
                              value={getValue({
                                month,
                                taxTypeId: taxItem.id,
                                year: state.selectedYear,
                              })}
                              variant="standard"
                            />
                          ) : (
                            <Typography noWrap>
                              {formatNumber(getValue({
                                month,
                                taxTypeId: taxItem.id,
                                year: state.selectedYear,
                              }))}
                            </Typography>
                          )}
                      </div>
                    </div>
                  ))}
                  <div
                    className={classNames(
                      classes.cellMeasureTypeContainer,
                      taxItem.id === state.hover.taxTypeId
                      && classes.hovered
                    )}
                  >
                    <div className={classes.cellTax}>
                      <Typography
                        color="secondary"
                        variant="caption"
                      >
                        {taxItem.measureType}
                      </Typography>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </CardContent>
          {state.isEditingMode && (
            <CardActions>
              <Button
                onClick={onCancelEdit}
                variant="secondary"
              >
                <Typography color="inherit">
                  {formatMessage({ id: 'cancel' })}
                </Typography>
              </Button>
              <Button
                isLoading={isFetchingSave}
                disabled={!state.editableItems.length}
                onClick={onSave}
                variant="primary"
              >
                <Typography color="inherit">
                  {formatMessage({ id: 'save' })}
                </Typography>
              </Button>
            </CardActions>
          )}
        </Card>
      )}
      <Snackbar
        autoHide
        onClose={onCloseAlert}
        open={state.showAfterSaveAlert
          && !isFailedSave}
      >
        <Card variant="success">
          <CardTitle>
            <Typography color="success">
              {formatMessage({ id: 'save.success' })}
            </Typography>
          </CardTitle>
        </Card>
      </Snackbar>
      <Snackbar
        onClose={(event: any, reason: string) => {
          if (reason !== 'clickaway') {
            onCloseAlert();
          }
        }}
        open={state.showAfterSaveAlert
          && isFailedSave}
      >
        <Card variant="error">
          <CardTitle>
            <Typography color="error">
              {formatMessage({ id: 'save.error' })}
            </Typography>
            <IconButton
              disableHoverSpace
              onClick={onCloseAlert}
            >
              <IconClose size={24}/>
            </IconButton>
          </CardTitle>
        </Card>
      </Snackbar>
    </div>
  );
}

export default Taxes;
