import {
  ERROR_RECEIVE_TYPES,
  RECEIVE_TYPES,
  REQUEST_TYPES,
} from '../constants/actionTypes';

import axios from 'axios';
import config from 'config';

const requestTypes = () => ({
  type: REQUEST_TYPES,
});

const receiveTypes = (response: any) => ({
  payload: response,
  type: RECEIVE_TYPES,
});

const errorReceiveTypes = () => ({
  type: ERROR_RECEIVE_TYPES,
});

const getTypes = () => {
  const {
    BASE_URL,
    TAX_TYPE_SERVICE,
  } = config;

  return axios
    .get(`${BASE_URL}${TAX_TYPE_SERVICE}`)
    .then(({ data }) => data);
};

const fetchTypes = () => (dispatch: any) => {
  dispatch(requestTypes());
  return getTypes()
    .then(types => dispatch(receiveTypes(types)))
    .catch(() => dispatch(errorReceiveTypes()));
};

const exportFunctions = {
  fetchTypes,
};

export default exportFunctions;
