export const clientsAndProjects = 'clientsAndProjects';
export const commonSettings = 'commonSettings';
export const compensationTypes = 'compensationTypes';
export const costItems = 'costItems';
export const contractors = 'contractors';
export const contractTypes = 'contractTypes';
export const currencyRates = 'currencyRates';
export const defaultPage = 'default';
export const expenses = 'expenses';
export const invoices = 'invoices';
export const login = 'login';
export const payrolls = 'payrolls';
export const reports = 'reports';
export const settings = 'settings';
export const taxes = 'taxes';
export const taxTypes = 'taxTypes';
export const userManagement = 'userManagement';
