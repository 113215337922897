import {
  ERROR_CREATE_TYPE,
  ERROR_DELETE_TYPE,
  ERROR_RECEIVE_TYPES,
  ERROR_UPDATE_TYPE,
  RECEIVE_TYPES,
  REQUEST_CREATE_TYPE,
  REQUEST_DELETE_TYPE,
  REQUEST_TYPES,
  REQUEST_UPDATE_TYPE,
  RESET_REDUCERS_DATA,
  SUCCESS_CREATE_TYPE,
  SUCCESS_DELETE_TYPE,
  SUCCESS_UPDATE_TYPE,
} from '../constants/actionTypes';

type Action = {
  payload: any,
  type: string,
}

const initialState = {
  errorMessage: '',
  isFailed: false,
  isFailedCreate: false,
  isFailedDelete: false,
  isFailedUpdate: false,
  isFetching: false,
  isFetchingCreate: false,
  isFetchingDelete: false,
  isFetchingUpdate: false,
  isFinishedCreate: false,
  isFinishedDelete: false,
  isFinishedUpdate: false,
  list: [],
};

const convertType = (item: any) => ({
  active: item.active,
  id: item.id,
  measureType: item.measureType,
  name: item.name,
});

const convertTypes = (input: any) => input
  .map((item: any) => convertType(item));

export default function Reducer(state = initialState, action: Action) {
  switch (action.type) {
    case REQUEST_TYPES: {
      return {
        ...state,
        isFailed: false,
        isFetching: true,
      };
    }

    case RECEIVE_TYPES: {
      const list = convertTypes(action.payload);
      return {
        ...state,
        isFailed: false,
        isFetching: false,
        list: list,
      };
    }

    case ERROR_RECEIVE_TYPES: {
      return {
        ...state,
        isFailed: true,
        isFetching: false,
      };
    }

    case REQUEST_CREATE_TYPE: {
      return {
        ...state,
        errorMessage: '',
        isFailedCreate: false,
        isFetchingCreate: true,
        isFinishedCreate: false,
      };
    }

    case SUCCESS_CREATE_TYPE: {
      const list = convertTypes(action.payload);
      return {
        ...state,
        errorMessage: '',
        isFailedCreate: false,
        isFetchingCreate: false,
        isFinishedCreate: true,
        list,
      };
    }

    case ERROR_CREATE_TYPE: {
      const error = action.payload;
      const errorMessage = error.response?.data?.message;
      return {
        ...state,
        errorMessage,
        isFailedCreate: true,
        isFetchingCreate: false,
        isFinishedCreate: true,
      };
    }

    case REQUEST_UPDATE_TYPE: {
      return {
        ...state,
        errorMessage: '',
        isFailedUpdate: false,
        isFetchingUpdate: true,
        isFinishedUpdate: false,
      };
    }

    case SUCCESS_UPDATE_TYPE: {
      const list = convertTypes(action.payload);
      return {
        ...state,
        errorMessage: '',
        isFailedUpdate: false,
        isFetchingUpdate: false,
        isFinishedUpdate: true,
        list,
      };
    }

    case ERROR_UPDATE_TYPE: {
      const error = action.payload;
      const errorMessage = error.response?.data?.message;
      return {
        ...state,
        errorMessage,
        isFailedUpdate: true,
        isFetchingUpdate: false,
        isFinishedUpdate: true,
      };
    }

    case REQUEST_DELETE_TYPE: {
      return {
        ...state,
        errorMessage: '',
        isFailedDelete: false,
        isFetchingDelete: true,
        isFinishedDelete: false,
      };
    }

    case SUCCESS_DELETE_TYPE: {
      const list = convertTypes(action.payload);
      return {
        ...state,
        errorMessage: '',
        isFailedDelete: false,
        isFetchingDelete: false,
        isFinishedDelete: true,
        list,
      };
    }

    case ERROR_DELETE_TYPE: {
      const error = action.payload;
      const errorMessage = error.response?.data?.message;
      return {
        ...state,
        errorMessage,
        isFailedDelete: true,
        isFetchingDelete: false,
        isFinishedDelete: true,
      };
    }

    case RESET_REDUCERS_DATA: return initialState;

    default: return state;
  }
}
