import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { makeStyles } from 'tss-react/mui';
import { useIntl } from 'react-intl';
import Accordion from 'components/Accordion';
import AccordionDetails from 'components/AccordionDetails';
import AccordionSummary from 'components/AccordionSummary';
import Breadcrumb from 'components/Breadcrumb';
import Breadcrumbs from 'components/Breadcrumbs';
import Button from 'components/Button';
import Card from 'components/Card';
import CardActions from 'components/CardActions';
import CardContent from 'components/CardContent';
import CardTitle from 'components/CardTitle';
import Chip from 'components/Chip';
import CircularProgress from 'components/CircularProgress';
import Checkbox from 'components/Checkbox';
import Dialog from 'components/Dialog';
import Divider from 'components/Divider';
import IconActions from 'components/icons/Actions';
import IconButton from 'components/IconButton';
import IconChart from 'components/icons/Chart';
import IconClose from 'components/icons/Close';
import IconDelete from 'components/icons/Delete';
import IconDownload from 'components/icons/Download';
import IconMail from 'components/icons/Mail';
import IconEdit from 'components/icons/Edit';
import IconError from 'components/icons/Error';
import IconExpandMore from 'components/icons/ExpandMore';
import IconHistory from 'components/icons/History';
import IconInfo from 'components/icons/Info';
import IconRefresh from 'components/icons/Refresh';
import Loading from 'components/Loading';
import Menu from 'components/Menu';
import MenuItem from 'components/MenuItem';
import moment from 'moment';
import Select from 'components/Select';
import Scheduler from 'components/Scheduler';
import Snackbar from 'components/Snackbar';
import TextField from 'components/TextField';
import Tooltip from 'components/Tooltip';
import Typography from 'components/Typography';
import useIsMobile from 'hooks/useIsMobile';
import useTheme from 'hooks/useTheme';
import 'moment/locale/uk';

import actionsContractTypes from '../actions/contractTypes';
import actionsSetup from '../actions/setup';
import actionsPayrolls from '../actions/payrolls';
import actionsReports from '../actions/reports';
import statusesList, * as payrollStatuses from '../constants/payrollStatuses';
import sendingInvoicesStatuses from '../constants/sendingInvoicesStatuses';

const getClasses = makeStyles<any>()((_, theme: any, classes: any) => ({
  actionsContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  actionsInnerContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: `${theme.spacing(1)}px`,
  },
  cell: {
    minWidth: '50%',
  },
  cellDetails: {
    alignItems: 'center',
    display: 'flex',
    gap: `${theme.spacing(0.5)}px`,
    width: '60%',
  },
  cellHistory: {
    width: '40%',
  },
  checkbox: {
    alignItems: 'center',
    display: 'flex',
  },
  chevron: {
    transform: 'rotate(0)',
    transition: 'all 0.2s linear',
  },
  chevronRotated: {
    transform: 'rotate(180deg)',
    transition: 'all 0.2s linear',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(2)}px`,
  },
  detailsEdit: {
    display: 'flex',
    flex: 1.5,
    justifyContent: 'flex-end',
    visibility: 'hidden',
  },
  detailsHours: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  detailsMobileContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(1.5)}px`,
    padding: `0px 0px ${theme.spacing(2)}px`,
  },
  detailsPercent: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
  },
  detailsPercentMobile: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    gap: `${theme.spacing(0.5)}px`,
    justifyContent: 'flex-end',
  },
  detailsProject: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  detailsProjectMobile: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  detailsRowContainer: {
    [`&:hover .${classes.detailsEdit}`]: {
      visibility: 'visible',
    },
    alignItems: 'center',
    display: 'flex',
    gap: `${theme.spacing(0.5)}px`,
  },
  detailsSourceHours: {
    display: 'flex',
    flex: 0.1,
  },
  detailsSum: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  detailsTitleContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialogContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(3)}px`,
    overflow: 'hidden',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(2)}px`,
    overflow: 'hidden',
  },
  dialogContentMobile: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(1)}px`,
  },
  historyItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(0.5)}px`,
  },
  menuItem: {
    alignItems: 'center',
    display: 'flex',
    gap: `${theme.spacing(1.5)}px`,
    padding: `${theme.spacing(0.5)}px 0px`,
  },
  payrollDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(1)}px`,
    padding: `${theme.spacing(1)}px 0px 0px ${theme.spacing(1)}px`,
  },
  payrollDetailsContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(1.5)}px`,
    padding: `0px ${theme.spacing(1)}px`,
  },
  payrollSummaryContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  payrollSummaryContainerMobile: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  payrollTitleContainerMobile: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: `0px ${theme.spacing(1)}px`,
    width: '100%',
  },
  payrollsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    alignItems: 'center',
    display: 'flex',
    minHeight: '20px',
  },
  selectMonth: {
    width: '150px',
  },
  selectYear: {
    width: '80px',
  },
  textField: {
    width: '80px',
  },
  textFieldHours: {
    width: '90px',
  },
  titleActions: {
    display: 'flex',
    flex: 1,
    gap: `${theme.spacing(1)}px`,
    justifyContent: 'flex-end',
  },
  titleActionsContainer: {
    display: 'flex',
    flex: 1.5,
    justifyContent: 'flex-end',
  },
  titleCell: {
    flex: 1,
  },
  titleStatus: {
    display: 'flex',
    flex: 9,
    justifyContent: 'center',
  },
  titleSum: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  valueContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
}));

const errorTypes = {
  INVALID_FIELD_HOURS: 'INVALID_FIELD_HOURS',
};
const ALL = 'all';
const MIN_YEAR = 2023;
const CURRENT_YEAR = moment().year();
const DEFAULT_MONTH = moment().month()
  || moment().endOf('year').month() + 1;
const DEFAULT_YEAR = moment().month() ? CURRENT_YEAR : CURRENT_YEAR - 1;

const months = Array
  .from(new Array(12).keys())
  .map(key => key + 1);

const years = [
  CURRENT_YEAR + 1,
  CURRENT_YEAR,
  ...Array
    .from(new Array(CURRENT_YEAR - MIN_YEAR).keys())
    .map(index => MIN_YEAR + index)
    .reverse(),
];

const colorsToPayrollStatuses: {
  [key: string]: 'warning' | 'success' | 'error'| 'primary' | 'secondary',
} = {
  [payrollStatuses.CONFIRMED]: 'success',
  [payrollStatuses.ERROR]: 'error',
  [payrollStatuses.NEW]: 'warning',
  [payrollStatuses.SENT]: 'primary',
  [payrollStatuses.ON_PAYMENT]: 'secondary',
};

const formatPercent = (value: any) => `${value}%`;

const formatNumber = (value: any) => value
  ? value.toString().replace('.', ',')
  : value;

const formatCurrency = (value: any) => value
  ? formatNumber(value
    .toFixed(2)
    // find number that is followed by a set of 3 numbers and insert a space
    .replace(/\d(?=(\d{3})+\.)/g, '$& '))
  : value;

function Payrolls() {
  const { theme } = useTheme();
  const { classes } = getClasses(theme);
  const { formatMessage } = useIntl();
  const dispatch: Dispatch<any> = useDispatch();
  const isMobile = useIsMobile();

  const {
    changes: payrollChanges,
    errorText: payrollErrorMessage,
    isFailed: isFailedPayrolls,
    isFailedPayrollChangeHistory,
    isFailedPayrollItemDetails,
    isFailedRefresh,
    isFailedRefreshPayroll,
    isFailedUpdatePayrollItem,
    isFailedUpdatePayrollStatus,
    isFailedDeletePayroll,
    isFailedFetchingSentInvoices,
    isFetching: isFetchingPayrolls,
    isFetchingPayroll,
    isFetchingPayrollChangeHistory,
    isFetchingPayrollItemDetails,
    isFetchingRefresh,
    isFetchingRefreshPayroll,
    isFetchingUpdatePayrollItem,
    isFetchingUpdatePayrollStatus,
    isFetchingSentInvoices,
    isFetchingDeletePayroll,
    isFinishedPayrollChangeHistory,
    isFinishedPayrollItemDetails,
    isSuccessRefresh,
    isSuccessRefreshPayroll,
    isSuccessUpdatePayrollItem,
    isSuccessUpdatePayrollStatus,
    isSuccessFetchingSentInvoices,
    isSuccessDeletePayroll,
    itemDetails,
    list: payrolls,
    sendingInvoicesStatus,
  } = useSelector(({ payrolls: reducerPayrolls }: any) => reducerPayrolls);

  const {
    errorMessage,
    isFailedDownloadInvoice,
    isFailedDownloadRegistry,
    isFailedSendingConfirmedInvoices,
    isFailedSendInvoice,
    isFetchingDownloadInvoice,
    isFetchingDownloadRegistry,
    isFetchingSendInvoice,
    isSuccessSendInvoice,
    isSuccessSendConfirmedInvoices,
  } = useSelector(({ reports: reducerReports }: any) => reducerReports);

  const {
    isFetching: isFetchingContractTypes,
    list: contractTypes,
  } = useSelector(({
    contractTypes: reducerContractTypes,
  }: any) => reducerContractTypes);

  const [state, setState] = useState({
    contractTypeId: ALL,
    currentPayrollStatus: null,
    editPayrollId: null,
    editPayrollItemId: null,
    editPayrollStatusesId: null,
    expandedPayrollsIds: [] as string[],
    isActionsMenuOpen: false,
    isEditPayrollStatusesOpen: false,
    month: DEFAULT_MONTH,
    payrollActionsId: null,
    payrollActionsMenuAnchor: null,
    payrollActionsStatus: null,
    payrollHistoryId: null,
    payrollIdToDelete: null,
    payrollItemHours: 0,
    statusesMenuAnchor: null,
    syncWithInvoice: false,
    validationErrors: [] as string[],
    year: DEFAULT_YEAR,
  });

  const isFetching = isFetchingPayrolls || isFetchingRefresh
    || isFetchingContractTypes;
  const isFetchingPayrollAction = isFetchingRefreshPayroll
    || isFetchingPayroll
    || isFetchingDownloadInvoice
    || isFetchingSendInvoice
    || isFetchingDeletePayroll;


  const showSuccessAfterSaveAlert = isSuccessRefresh
    || isSuccessRefreshPayroll
    || isSuccessSendInvoice
    || isSuccessUpdatePayrollStatus
    || isSuccessUpdatePayrollItem
    || isSuccessDeletePayroll
    || isSuccessFetchingSentInvoices;

  const showErrorAfterSaveAlert = isFailedRefresh
    || isFailedRefreshPayroll
    || isFailedSendInvoice
    || isFailedUpdatePayrollStatus
    || isFailedDownloadInvoice
    || isFailedDownloadRegistry
    || isFailedDeletePayroll
    || isFailedSendingConfirmedInvoices
    || isFailedFetchingSentInvoices;

  const onOpenActionMenu = (payroll: any, event: React.SyntheticEvent<any>) => {
    event.stopPropagation();
    setState({
      ...state,
      isActionsMenuOpen: true,
      payrollActionsId: payroll.id,
      payrollActionsMenuAnchor: event.currentTarget,
      payrollActionsStatus: payroll.status,
    });
  };

  const onOpenStatusMenu = (payroll: any, event: React.SyntheticEvent<any>) => {
    event.stopPropagation();
    setState({
      ...state,
      currentPayrollStatus: payroll.status,
      editPayrollStatusesId: payroll.id,
      isEditPayrollStatusesOpen: true,
      statusesMenuAnchor: event.currentTarget,
    });
  };

  const onEditPayrollItem = (payrollId: any, itemId: any) => {
    dispatch(actionsSetup.clearAfterFetchStatus());
    dispatch(actionsPayrolls.fetchPayrollItemDetails({
      itemId,
      payrollId,
    }));
    setState({
      ...state,
      editPayrollId: payrollId,
      editPayrollItemId: itemId,
    });
  };

  const onCloseStatusesMenu = () => setState({
    ...state,
    currentPayrollStatus: null,
    editPayrollStatusesId: null,
  });

  const onCloseActionsMenu = () => setState({
    ...state,
    isActionsMenuOpen: false,
  });

  const onCloseUpdateItemDialog = () => setState({
    ...state,
    editPayrollItemId: null,
  });

  const onCloseHistoryDialog = () => setState({
    ...state,
    payrollHistoryId: null,
  });

  const onCloseDeleteDialog = () => setState({
    ...state,
    isActionsMenuOpen: false,
    payrollIdToDelete: null,
  });

  const onExpandAccordion = (payrollId: string) => {
    const expandedPayrollsIds = state.expandedPayrollsIds.includes(payrollId)
      ? state.expandedPayrollsIds.filter(id => id !== payrollId)
      : state.expandedPayrollsIds.concat(payrollId);
    setState({
      ...state,
      expandedPayrollsIds,
    });
  };

  const getContractorName = (itemId: any) => payrolls
    .find((payroll: any) => payroll.items
      .find((payrollItem: any) => payrollItem.id === itemId))
    ?.contractor;

  const getValidationErrors = () => {
    const errors = [];
    if (state.payrollItemHours < 0) {
      errors.push(errorTypes.INVALID_FIELD_HOURS);
    }

    return errors;
  };

  const getValidContractTypeId = (contractTypeId: string) =>
    contractTypes.find(({ id }: { id: any }) =>
      id === contractTypeId)?.id || null;

  const onSavePayrollItem = () => {
    const validationErrors = getValidationErrors();
    if (!validationErrors.length) {
      dispatch(actionsSetup.clearAfterFetchStatus());
      dispatch(actionsPayrolls.fetchUpdatePayrollItem({
        hours: state.payrollItemHours,
        itemId: state.editPayrollItemId,
        payrollId: state.editPayrollId,
        syncWithInvoice: state.syncWithInvoice,
      }));
    }
    setState({
      ...state,
      validationErrors,
    });
  };

  const colorsToPayrollHistory = useMemo(() => ({
    [payrollStatuses.CONFIRMED]: theme.colors.greenLight,
    [payrollStatuses.ERROR]: theme.colors.redLight,
    [payrollStatuses.NEW]: theme.colors.yellowLight,
    [payrollStatuses.SENT]: theme.colors.blueDark,
    [payrollStatuses.ON_PAYMENT]: theme.colors.purpleLight,
  } as any), [theme]);

  const errorAlertLabel = useMemo(() => {
    let intlValue = 'error';
    if (isFailedRefresh) {
      intlValue = 'errorRefresh';
    }
    if (isFailedSendInvoice) {
      intlValue = 'errorSendInvoice';
    }
    if (isFailedDownloadInvoice) {
      intlValue = 'errorDownloadInvoice';
    }
    if (isFailedDownloadRegistry) {
      intlValue = 'errorDownloadRegistry';
    }
    if (isFailedDeletePayroll) {
      intlValue = 'errorDeletePayroll';
    }
    if (isFailedRefreshPayroll || isFailedUpdatePayrollStatus) {
      intlValue = 'errorUpdatePayroll';
    }
    if (isFailedSendingConfirmedInvoices || isFailedFetchingSentInvoices) {
      intlValue = 'errorSendingConfirmedInvoices';
    }
    return formatMessage({ id: `loading.${intlValue}` });
  }, [showErrorAfterSaveAlert]);

  const errorMsg = errorMessage || payrollErrorMessage;

  const successAlertLabel = useMemo(() => {
    let intlValue = 'success';
    if (isSuccessRefresh) {
      intlValue = 'successRefresh';
    }
    if (isSuccessSendInvoice) {
      intlValue = 'successSendInvoice';
    }
    if (isSuccessDeletePayroll) {
      intlValue = 'successDeletePayroll';
    }
    if (isSuccessRefreshPayroll
      || isSuccessUpdatePayrollStatus
      || isSuccessUpdatePayrollItem
    ) {
      intlValue = 'successUpdatePayroll';
    }
    if (isSuccessFetchingSentInvoices) {
      intlValue = 'successSendingConfirmedInvoices';
    }
    return formatMessage({ id: `loading.${intlValue}` });
  }, [showSuccessAfterSaveAlert]);

  useEffect(() => {
    dispatch(actionsSetup.clearAfterFetchStatus());
    dispatch(actionsPayrolls.fetchPayrolls({
      contractTypeId: getValidContractTypeId(state.contractTypeId),
      month: state.month,
      year: state.year,
    }));
    dispatch(actionsPayrolls.fetchSendingInvoicesStatus(false));
  }, [
    state.contractTypeId,
    state.month,
    state.year,
  ]);

  useEffect(() => {
    if (isSuccessUpdatePayrollStatus) {
      setState({
        ...state,
        currentPayrollStatus: null,
        editPayrollStatusesId: null,
      });
    }
  }, [isSuccessUpdatePayrollStatus]);

  useEffect(() => {
    if (isFinishedPayrollItemDetails) {
      const payrollItemHours = itemDetails.hours || 0;
      const syncWithInvoice = !isNaN(itemDetails.projectInvoiceHours);
      setState({
        ...state,
        payrollItemHours,
        syncWithInvoice,
      });
    }
  }, [isFinishedPayrollItemDetails]);

  useEffect(() => {
    if (isSuccessSendConfirmedInvoices) {
      dispatch(actionsPayrolls.fetchSendingInvoicesStatus());
    }
  }, [isSuccessSendConfirmedInvoices]);

  useEffect(() => {
    if (isSuccessUpdatePayrollItem) {
      setState({
        ...state,
        editPayrollItemId: null,
        payrollItemHours: 0,
        syncWithInvoice: false,
      });
    }
  }, [isSuccessUpdatePayrollItem]);

  useEffect(() => {
    if (isSuccessSendInvoice) {
      dispatch(actionsPayrolls.fetchPayroll({
        payrollId: state.payrollActionsId,
      }));
    }
  }, [isSuccessSendInvoice]);

  useEffect(() => {
    dispatch(actionsContractTypes.fetchContractTypes(true));

    return () => {
      dispatch(actionsSetup.resetReducersData());
    };
  }, []);

  return (
    <div className={classes.container}>
      <Breadcrumbs>
        <Breadcrumb
          label={formatMessage({ id: 'payrolls' })}
          variant="text"
        />
      </Breadcrumbs>
      <div className={classes.actionsContainer}>
        <div className={classes.actionsInnerContainer}>
          <div className={classes.selectMonth}>
            <Select
              fullWidth
              onChange={({ target }) => setState({
                ...state,
                month: target.value,
              })}
              size="small"
              value={state.month}
              variant="outlined"
            >
              {months.map((month) => (
                <MenuItem value={month}>
                  <Typography>
                    {formatMessage({ id: `month.${month}` })}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className={classes.selectYear}>
            <Select
              fullWidth
              onChange={({ target }) => setState({
                ...state,
                year: target.value,
              })}
              size="small"
              value={state.year}
              variant="outlined"
            >
              {years.map(year => (
                <MenuItem value={year}>
                  <Typography>
                    {year}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </div>
          <Select
            disabled={isFetching}
            onChange={({ target }) => setState({
              ...state,
              contractTypeId: target.value,
            })}
            size="small"
            value={state.contractTypeId}
            variant="outlined"
          >
            <MenuItem value={ALL}>
              <Typography>
                {formatMessage({ id: ALL })}
              </Typography>
            </MenuItem>
            {contractTypes.map((type: any) => (
              <MenuItem value={type.id}>
                <Typography>
                  {type.name}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={classes.actionsContainer}>
          {<Scheduler action={
            () => dispatch(actionsPayrolls.fetchSendingInvoicesStatus())
          }
          status={
            sendingInvoicesStatus === sendingInvoicesStatuses.IN_PROGRESS
              ? 'active'
              : 'inactive'
          }/>}
          {!!payrolls
            .some((p: any)=> p.status === payrollStatuses.CONFIRMED)
              && (
                <Tooltip
                  title={formatMessage({ id: 'sendConfirmedInvoices' })}
                >
                  <IconButton
                    disabled={isFetching || isFetchingSentInvoices}
                    onClick={() => {
                      dispatch(actionsSetup.clearAfterFetchStatus());
                      dispatch(actionsReports.fetchSendConfirmedInvoices({
                        contractTypeId: getValidContractTypeId(
                          state.contractTypeId
                        ),
                        month: state.month,
                        year: state.year,
                      }));
                    }}
                  >
                    {isFetchingSentInvoices
                      ? <CircularProgress size={24} />
                      : <IconMail size={24} />
                    }
                  </IconButton>
                </Tooltip>
              )}
          {!!payrolls.length && state.contractTypeId !== ALL && (
            <Tooltip
              title={formatMessage({ id: 'downloadRegistry' })}
            >
              <IconButton
                disabled={isFetching || isFetchingDownloadRegistry}
                onClick={() => {
                  dispatch(actionsSetup.clearAfterFetchStatus());
                  dispatch(actionsReports.fetchDownloadRegistry({
                    contractTypeId: state.contractTypeId,
                    month: state.month,
                    year: state.year,
                  }));
                }}
              >
                {isFetchingDownloadRegistry
                  ? <CircularProgress size={24} />
                  : <IconDownload size={24} />
                }
              </IconButton>
            </Tooltip>
          )}
          <Tooltip
            title={formatMessage({ id: 'refresh' })}
          >
            <IconButton
              disabled={isFetching}
              onClick={() => {
                dispatch(actionsSetup.clearAfterFetchStatus());
                dispatch(actionsPayrolls.fetchRefreshPayrolls({
                  contractTypeId: getValidContractTypeId(
                    state.contractTypeId
                  ),
                  month: state.month,
                  year: state.year,
                }));
              }}
            >
              {isFetching
                ? <CircularProgress size={24} />
                : <IconRefresh size={24} />
              }
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {(isFetchingPayrolls || isFailedPayrolls) && (
        <Loading variant={isFailedPayrolls ? 'error' : 'loading'}>
          {isFailedPayrolls && (
            <Typography color="secondary" variant="subtitle">
              {formatMessage({ id: 'loading.error' })}
            </Typography>
          )}
        </Loading>
      )}
      {(!isFetchingPayrolls && !isFailedPayrolls)
        && !payrolls.length
        && (
          <Loading variant="noData">
            <Typography color="secondary" variant="subtitle">
              {formatMessage({ id: 'loading.noData' })}
            </Typography>
          </Loading>
        )}
      {(!isFetchingPayrolls && !isFailedPayrolls) && (
        <div>
          {payrolls.map((payroll: any) => (
            <>
              {!isMobile && (
                <Accordion
                  expanded={state.expandedPayrollsIds.includes(payroll.id)}
                  onChange={() => onExpandAccordion(payroll.id)}
                >
                  <AccordionSummary>
                    <div className={classes.payrollSummaryContainer}>
                      <div className={classes.titleCell}>
                        <Typography>
                          {payroll.contractor}
                        </Typography>
                      </div>
                      <div className={classes.titleCell}>
                        <Typography align="right">
                          {payroll.totalHours
                            ? formatMessage(
                              { id: 'hours' },
                              { hours: formatNumber(payroll.totalHours) }
                            )
                            : ''}
                        </Typography>
                      </div>
                      <div className={classes.titleCell}>
                        <Typography align="right">
                          {payroll.totalPercent === null
                            ? ''
                            : formatPercent(formatNumber(payroll.totalPercent))}
                        </Typography>
                      </div>
                      <div className={classes.titleSum}>
                        <Typography>
                          <strong>
                            {formatMessage(
                              { id: 'sum' },
                              { sum: formatCurrency(payroll.sum) }
                            )}
                          </strong>
                        </Typography>
                      </div>
                      <div className={classes.titleActionsContainer}>
                        <div className={classes.titleStatus}>
                          {isFetchingUpdatePayrollStatus
                          && payroll.id === state.editPayrollStatusesId
                            ? <CircularProgress />
                            : (
                              <Tooltip
                                arrow
                                placement='bottom'
                                title={(
                                  payroll.status === payrollStatuses.ERROR
                                  || payroll.status === payrollStatuses
                                    .ON_PAYMENT
                                ) && payroll.statusDescription}
                              >
                                <Chip
                                  color={colorsToPayrollStatuses[payroll
                                    .status]}
                                  disabled={isFetchingUpdatePayrollStatus
                                    && payroll.id !== state
                                      .editPayrollStatusesId}
                                  label={formatMessage({
                                    id: `status.${payroll.status}`,
                                  })}
                                  onClick={ev => onOpenStatusMenu(payroll, ev)}
                                />
                              </Tooltip>
                            )}
                        </div>
                        <div className={classes.titleActions}>
                          <IconButton
                            disabled={isFetchingPayrollAction
                              && state.payrollActionsId !== payroll.id}
                            disableHoverSpace
                            onClick={ev => onOpenActionMenu(payroll, ev)}
                          >
                            {isFetchingPayrollAction
                            && state.payrollActionsId === payroll.id
                              ? <CircularProgress size={24} />
                              : <IconActions size={24} />}
                          </IconButton>
                          <div
                            className={state.expandedPayrollsIds
                              .includes(payroll.id)
                              ? classes.chevronRotated
                              : classes.chevron}
                          >
                            <IconButton
                              disableHoverSpace
                              onClick={() => onExpandAccordion(payroll.id)}
                            >
                              <IconExpandMore size={24} />
                            </IconButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </AccordionSummary>
                  <Divider />
                  <AccordionDetails>
                    <div className={classes.payrollDetailsContainer}>
                      {payroll.items
                        ?.map((payrollItem: any) => (
                          <div className={classes.detailsRowContainer}>
                            <div className={classes.detailsProject}>
                              <Typography>
                                {payrollItem.compensationFor}
                              </Typography>
                              <Typography color="secondary">
                                {payrollItem.comment}
                              </Typography>
                            </div>
                            <div className={classes.detailsHours}>
                              <Typography align="right">
                                {!isNaN(payrollItem.hours)
                                  ? formatMessage(
                                    { id: 'hours' },
                                    { hours: formatNumber(payrollItem.hours) }
                                  )
                                  : ''}
                              </Typography>
                            </div>
                            <div className={classes.detailsPercent}>
                              <div>
                                {payrollItem.sourceHours !== payrollItem.hours
                                && (
                                  <Tooltip
                                    arrow
                                    placement="top"
                                    title={formatMessage(
                                      { id: 'hours.source.hint' },
                                      {
                                        value: formatNumber(payrollItem
                                          .sourceHours),
                                      }
                                    )}
                                  >
                                    <IconInfo
                                      color={theme.colors.yellowDark}
                                      size={16}
                                    />
                                  </Tooltip>
                                )}
                              </div>
                              <Typography align="right">
                                {!isNaN(payrollItem.percent)
                                  ? formatPercent(formatNumber(payrollItem
                                    .percent))
                                  : ''}
                              </Typography>
                            </div>
                            <div className={classes.detailsSum}>
                              <Typography align="right">
                                {formatMessage(
                                  { id: 'sum' },
                                  { sum: formatCurrency(payrollItem.sum) }
                                )}
                              </Typography>
                            </div>
                            <div className={classes.detailsEdit}>
                              {!isNaN(payrollItem.hours) && (
                                <IconButton
                                  disabled={isFetchingUpdatePayrollStatus
                                  || isFetchingPayrollAction}
                                  disableHoverSpace
                                  onClick={() => onEditPayrollItem(
                                    payroll.id,
                                    payrollItem.id
                                  )}
                                >
                                  <IconEdit size={16} />
                                </IconButton>
                              )}
                            </div>
                          </div>
                        ))}
                      {!payroll.items?.length && (
                        <Typography color="secondary">
                          {payroll.status === payrollStatuses.ERROR
                            ? payroll.statusDescription
                            || formatMessage({ id: 'error' })
                            : formatMessage({ id: 'noTimeReported' })}
                        </Typography>
                      )}
                    </div>
                  </AccordionDetails>
                </Accordion>
              )}
              {isMobile && (
                <Accordion
                  expanded={state.expandedPayrollsIds.includes(payroll.id)}
                  onChange={() => onExpandAccordion(payroll.id)}
                >
                  <AccordionSummary disablePadding>
                    <div className={classes.payrollTitleContainerMobile}>
                      <div className={classes.titleCell}>
                        <Typography>
                          {payroll.contractor}
                        </Typography>
                      </div>
                      <div className={classes.titleSum}>
                        <Typography>
                          <strong>
                            {formatMessage(
                              { id: 'sum' },
                              { sum: formatCurrency(payroll.sum) }
                            )}
                          </strong>
                        </Typography>
                      </div>
                      <div className={classes.titleActionsContainer}>
                        <div className={classes.titleStatus}>
                          {isFetchingUpdatePayrollStatus
                          && payroll.id === state.editPayrollStatusesId
                            ? <CircularProgress />
                            : (
                              <Tooltip
                                arrow
                                placement='bottom'
                                title={(
                                  payroll.status === payrollStatuses.ERROR
                                  || payroll.status === payrollStatuses
                                    .ON_PAYMENT
                                ) && payroll.statusDescription}
                              >
                                <Chip
                                  color={colorsToPayrollStatuses[payroll
                                    .status]}
                                  disabled={isFetchingUpdatePayrollStatus
                                    && payroll.id !== state
                                      .editPayrollStatusesId}
                                  label={formatMessage({
                                    id: `status.${payroll.status}`,
                                  })}
                                  onClick={ev => onOpenStatusMenu(payroll, ev)}
                                />
                              </Tooltip>
                            )}
                        </div>
                        <div className={classes.titleActions}>
                          <IconButton
                            disabled={isFetchingPayrollAction
                              && state.payrollActionsId !== payroll.id}
                            disableHoverSpace
                            onClick={ev => onOpenActionMenu(payroll, ev)}
                          >
                            {isFetchingPayrollAction
                            && state.payrollActionsId === payroll.id
                              ? <CircularProgress size={24} />
                              : <IconActions size={24} />}
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails disablePadding>
                    <div className={classes.detailsMobileContainer}>
                      <div className={classes.payrollDetailsContainerMobile}>
                        {!!payroll.totalHours && (
                          <div
                            className={classes.payrollSummaryContainerMobile}
                          >
                            <div className={classes.titleCell}>
                              <Typography color="secondary">
                                {formatMessage({ id: 'totalHours' })}
                              </Typography>
                            </div>
                            <div className={classes.titleSum}>
                              <Typography align="right">
                                {formatNumber(payroll.totalHours)}
                              </Typography>
                            </div>
                            <div className={classes.titleActionsContainer} />
                          </div>
                        )}
                        {payroll.totalPercent !== null && (
                          <div
                            className={classes.payrollSummaryContainerMobile}
                          >
                            <div className={classes.titleCell}>
                              <Typography color="secondary">
                                {formatMessage({ id: 'totalPercent' })}
                              </Typography>
                            </div>
                            <div className={classes.titleSum}>
                              <Typography align="right">
                                {formatPercent(formatNumber(payroll
                                  .totalPercent))}
                              </Typography>
                            </div>
                            <div className={classes.titleActionsContainer} />
                          </div>
                        )}
                      </div>
                      <Divider />
                      {payroll.items
                        ?.map((payrollItem: any, idx: number) => (
                          <>
                            <div
                              className={classes.payrollDetailsContainerMobile}
                            >
                              <div
                                className={classes
                                  .payrollSummaryContainerMobile}
                              >
                                <div className={classes.detailsProjectMobile}>
                                  <Typography>
                                    {payrollItem.compensationFor}
                                  </Typography>
                                  <Typography color="secondary">
                                    {payrollItem.comment}
                                  </Typography>
                                </div>
                                <div className={classes.titleCell}>
                                  <Typography align="right">
                                    {formatMessage(
                                      { id: 'sum' },
                                      { sum: formatCurrency(payrollItem.sum) }
                                    )}
                                  </Typography>
                                </div>
                                <div className={classes.titleActionsContainer}>
                                  {!isNaN(payrollItem.hours) && (
                                    <IconButton
                                      disabled={isFetchingUpdatePayrollStatus
                                      || isFetchingPayrollAction}
                                      disableHoverSpace
                                      onClick={() => onEditPayrollItem(
                                        payroll.id,
                                        payrollItem.id
                                      )}
                                    >
                                      <IconEdit size={16} />
                                    </IconButton>
                                  )}
                                </div>
                              </div>
                              {!isNaN(payrollItem.hours) && (
                                <div
                                  className={classes
                                    .payrollSummaryContainerMobile}
                                >
                                  <div className={classes.titleCell}>
                                    <Typography color="secondary">
                                      {formatMessage({ id: 'totalHours' })}
                                    </Typography>
                                  </div>
                                  <div className={classes.titleSum}>
                                    <Typography align="right">
                                      {formatNumber(payrollItem.hours)}
                                    </Typography>
                                  </div>
                                  <div
                                    className={classes.titleActionsContainer}
                                  />
                                </div>
                              )}
                              {!isNaN(payrollItem.percent) && (
                                <div
                                  className={classes
                                    .payrollSummaryContainerMobile}
                                >
                                  <div className={classes.titleCell}>
                                    <Typography color="secondary">
                                      {formatMessage({ id: 'totalPercent' })}
                                    </Typography>
                                  </div>
                                  <div className={classes.detailsPercentMobile}>
                                    <div>
                                      {payrollItem.sourceHours !== payrollItem
                                        .hours && (
                                        <Tooltip
                                          arrow
                                          placement="top"
                                          title={formatMessage(
                                            { id: 'hours.source.hint' },
                                            {
                                              value: formatNumber(payrollItem
                                                .sourceHours),
                                            }
                                          )}
                                        >
                                          <IconInfo
                                            color={theme.colors.yellowDark}
                                            size={16}
                                          />
                                        </Tooltip>
                                      )}
                                    </div>
                                    <Typography align="right">
                                      {formatPercent(formatNumber(payrollItem
                                        .percent))}
                                    </Typography>
                                  </div>
                                  <div
                                    className={classes.titleActionsContainer}
                                  />
                                </div>
                              )}
                            </div>
                            {!!payroll.items[idx + 1] && <Divider />}
                          </>
                        ))}
                      {!payroll.items?.length && (
                        <div className={classes.payrollDetailsContainerMobile}>
                          <Typography color="secondary">
                            {payroll.status === payrollStatuses.ERROR
                              ? payroll.statusDescription
                              || formatMessage({ id: 'error' })
                              : formatMessage({ id: 'noTimeReported' })}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </AccordionDetails>
                </Accordion>
              )}
            </>
          ))}
        </div>
      )}
      {state.editPayrollStatusesId && (
        <Menu
          anchorEl={state.statusesMenuAnchor}
          open={state.isEditPayrollStatusesOpen && state.editPayrollStatusesId}
          onClose={onCloseStatusesMenu}
        >
          {statusesList
            .map(status => (
              <MenuItem
                onClick={() => {
                  if (state.currentPayrollStatus !== status) {
                    dispatch(actionsSetup.clearAfterFetchStatus());
                    dispatch(actionsPayrolls.fetchUpdatePayrollStatus({
                      payrollId: state.editPayrollStatusesId,
                      status,
                    }));
                  }
                  setState({
                    ...state,
                    isEditPayrollStatusesOpen: false,
                  });
                }}
                selected={state.currentPayrollStatus === status}
                value={status}
              >
                <Typography>
                  {formatMessage({
                    id: `status.${status}`,
                  })}
                </Typography>
              </MenuItem>
            ))}
        </Menu>
      )}
      <Menu
        anchorEl={state.payrollActionsMenuAnchor}
        open={state.isActionsMenuOpen}
        onClose={onCloseActionsMenu}
      >
        <MenuItem
          onClick={() => {
            dispatch(actionsSetup.clearAfterFetchStatus());
            dispatch(actionsPayrolls.fetchRefreshPayroll({
              payrollId: state.payrollActionsId,
            }));
            onCloseActionsMenu();
          }}
        >
          <div className={classes.menuItem}>
            <IconRefresh size={24} />
            <Typography>
              {formatMessage({ id: 'refresh' })}
            </Typography>
          </div>
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch(actionsSetup.clearAfterFetchStatus());
            dispatch(actionsPayrolls.fetchChangeHistory({
              payrollId: state.payrollActionsId,
            }));
            setState({
              ...state,
              isActionsMenuOpen: false,
              payrollHistoryId: state.payrollActionsId,
            });
          }}
        >
          <div className={classes.menuItem}>
            <IconHistory size={24} />
            <Typography>
              {formatMessage({ id: 'changeHistory' })}
            </Typography>
          </div>
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch(actionsSetup.clearAfterFetchStatus());
            dispatch(actionsReports.fetchDownloadInvoice({
              payrollId: state.payrollActionsId,
            }));
            onCloseActionsMenu();
          }}
        >
          <div className={classes.menuItem}>
            <IconChart size={24} />
            <Typography>
              {formatMessage({ id: 'downloadInvoice' })}
            </Typography>
          </div>
        </MenuItem>
        {state.payrollActionsStatus !== payrollStatuses.ERROR && (
          <MenuItem
            onClick={() => {
              dispatch(actionsSetup.clearAfterFetchStatus());
              dispatch(actionsReports.fetchSendInvoice({
                payrollId: state.payrollActionsId,
              }));
              onCloseActionsMenu();
            }}
          >
            <div className={classes.menuItem}>
              <IconMail size={24} />
              <Typography>
                {formatMessage({ id: 'sendInvoice' })}
              </Typography>
            </div>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => setState({
            ...state,
            payrollIdToDelete: state.payrollActionsId,
          })}
        >
          <div className={classes.menuItem}>
            <IconDelete size={24} />
            <Typography>
              {formatMessage({ id: 'delete' })}
            </Typography>
          </div>
        </MenuItem>
      </Menu>

      {/* Changes History Dialog */}
      <Dialog
        onClose={onCloseHistoryDialog}
        open={!!state.payrollHistoryId}
      >
        {isFetchingPayrollChangeHistory && (
          <Loading variant="loading" />
        )}
        {isFinishedPayrollChangeHistory && (
          <Card>
            <CardTitle>
              <div>
                <Typography variant="title">
                  {payrolls.find((payroll: any) =>
                    payroll.id === state.payrollHistoryId)?.contractor}
                </Typography>
                <Typography color="secondary">
                  {formatMessage({ id: 'changeHistory' })}
                </Typography>
              </div>
              <IconButton
                disableHoverSpace
                onClick={onCloseHistoryDialog}
              >
                <IconClose size={24}/>
              </IconButton>
            </CardTitle>
            <div/>
            <CardContent>
              {isFailedPayrollChangeHistory
                && !payrollChanges.length
                && (
                  <Typography>
                    {formatMessage({ id: 'loading.error' })}
                  </Typography>
                )}
              {!isFailedPayrollChangeHistory
                && !payrollChanges.length
                && (
                  <Typography>
                    {formatMessage({ id: 'loading.noData' })}
                  </Typography>
                )}
              {!isFailedPayrollChangeHistory
                && !!payrollChanges.length
                && (
                  <div className={classes.dialogContent}>
                    {payrollChanges.map((historyEntry: any) => (
                      <Card
                        customBackground=
                          {colorsToPayrollHistory[historyEntry.status]}
                      >
                        <CardTitle>
                          <div>
                            <Typography>
                              <strong>
                                {historyEntry.user}
                              </strong>
                            </Typography>
                            <Typography color="secondary">
                              <strong>
                                {moment(historyEntry.timestamp)
                                  .format('DD/MM/YYYY, hh:mm:ss')}
                              </strong>
                            </Typography>
                          </div>
                        </CardTitle>
                        <CardContent>
                          <div className={classes.historyItemContainer}>
                            <div className={classes.row}>
                              <div className={classes.cellHistory}>
                                <Typography color="secondary">
                                  {formatMessage({ id: 'history.action' })}
                                </Typography>
                              </div>
                              <div className={classes.cellDetails}>
                                <Typography>
                                  {formatMessage({
                                    id: `history.action.${historyEntry.action}`,
                                  })}
                                </Typography>
                                {historyEntry.description && (
                                  <Tooltip
                                    arrow
                                    placement="top"
                                    title={historyEntry.description}
                                  >
                                    <IconInfo
                                      color={
                                        historyEntry.status ===
                                          payrollStatuses.ERROR
                                          ? theme.colors.yellowDark
                                          : theme.colors.cobalt}
                                      size={16}
                                    />
                                  </Tooltip>
                                )}
                              </div>
                            </div>
                            <div className={classes.row}>
                              <div className={classes.cellHistory}>
                                <Typography color="secondary">
                                  {formatMessage({ id: 'history.status' })}
                                </Typography>
                              </div>
                              <Typography>
                                <strong>
                                  {formatMessage({
                                    id: `status.${historyEntry.status}`,
                                  })}
                                </strong>
                              </Typography>
                            </div>
                            <div className={classes.row}>
                              <div className={classes.cellHistory}>
                                <Typography color="secondary">
                                  {formatMessage({ id: 'history.sum' })}
                                </Typography>
                              </div>
                              <Typography>
                                {formatMessage(
                                  { id: 'sum' },
                                  { sum: formatCurrency(historyEntry.sum) }
                                )}
                              </Typography>
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    ))}
                  </div>
                )}
            </CardContent>
            <div/>
            <CardActions>
              <Button
                onClick={onCloseHistoryDialog}
                variant="secondary"
              >
                <Typography color="inherit">
                  {formatMessage({ id: 'close' })}
                </Typography>
              </Button>
            </CardActions>
          </Card>
        )}
      </Dialog>

      {/* Item Update Dialog. Desktop */}
      <Dialog
        onClose={onCloseUpdateItemDialog}
        open={!!state.editPayrollItemId && !isMobile}
        maxWidth="xs"
      >
        {isFetchingPayrollItemDetails && (
          <Loading variant="loading" />
        )}
        {isFailedUpdatePayrollItem && (
          <Card variant="error">
            <CardTitle>
              <Typography color="error">
                {formatMessage({ id: 'loading.errorUpdatePayroll' })}
              </Typography>
            </CardTitle>
          </Card>
        )}
        {isFinishedPayrollItemDetails && (
          <Card variant='edit'>
            <CardTitle>
              <div>
                <Typography variant='title'>
                  {formatMessage({ id: 'edit.title' })}
                </Typography>
                {!isFailedPayrollItemDetails && (
                  <Typography color='secondary'>
                    {formatMessage(
                      { id: 'edit.subtitle' },
                      {
                        compensationFor: itemDetails.compensationFor,
                        contractor: getContractorName(itemDetails.id),
                      }
                    )}
                  </Typography>
                )}
              </div>
              <IconButton
                disableHoverSpace
                onClick={onCloseUpdateItemDialog}
              >
                <IconClose size={24}/>
              </IconButton>
            </CardTitle>
            <div/>
            <CardContent>
              {isFailedPayrollItemDetails && (
                <Typography>
                  {formatMessage({ id: 'loading.error' })}
                </Typography>
              )}
              {!isFailedPayrollItemDetails && (
                <div className={classes.dialogContent}>
                  <div className={classes.row}>
                    <div className={classes.cell}>
                      <Typography color='secondary'>
                        {formatMessage({ id: 'hours.source' })}
                      </Typography>
                    </div>
                    <Typography color='secondary'>
                      {formatNumber(itemDetails.sourceHours)}
                    </Typography>
                  </div>
                  <div className={classes.row}>
                    <div className={classes.cell}>
                      <Typography color='secondary'>
                        {formatMessage({ id: 'hours.worked' })}
                      </Typography>
                    </div>
                    <TextField
                      AdornmentEnd={
                        state.validationErrors
                          .includes(errorTypes.INVALID_FIELD_HOURS) && (
                          <Tooltip
                            arrow
                            placement="top"
                            title={formatMessage({
                              id: `validationError.${errorTypes
                                .INVALID_FIELD_HOURS}`,
                            })}
                          >
                            <IconError
                              color={theme.colors.redDark}
                              size={20}
                            />
                          </Tooltip>
                        )
                      }
                      disabled={isFetchingUpdatePayrollItem}
                      fullWidth
                      inputType='number'
                      isError={state.validationErrors
                        .includes(errorTypes.INVALID_FIELD_HOURS)}
                      onChange={({ target }) => setState({
                        ...state,
                        payrollItemHours: target.value,
                      })}
                      placeholder={formatMessage({
                        id: 'hours.worked',
                      })}
                      size='small'
                      value={state.payrollItemHours}
                      variant='standard'
                    />
                  </div>
                  {!isNaN(itemDetails.projectInvoiceHours) && (
                    <div className={classes.row}>
                      <div className={classes.cell}>
                        <Typography color='secondary'>
                          {formatMessage({ id: 'hours.invoice' })}
                        </Typography>
                      </div>
                      <div className={classes.valueContainer}>
                        <Typography color='secondary'>
                          {state.syncWithInvoice
                            ? formatNumber(state.payrollItemHours)
                            : formatNumber(itemDetails.projectInvoiceHours)}
                        </Typography>
                        <div className={classes.checkbox}>
                          <Checkbox
                            checked={state.syncWithInvoice}
                            disableHoverSpace
                            onChange={({ target }) => setState({
                              ...state,
                              syncWithInvoice: target.checked,
                            })}
                          />
                          <Typography
                            color="secondary"
                            variant="caption"
                          >
                            {formatMessage({ id: 'sync' })}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </CardContent>
            <div/>
            <CardActions>
              <Button
                onClick={onCloseUpdateItemDialog}
                variant='secondary'
              >
                <Typography color='inherit'>
                  {formatMessage({ id: 'cancel' })}
                </Typography>
              </Button>
              <Button
                disabled={isFailedPayrollItemDetails}
                isLoading={isFetchingUpdatePayrollItem}
                onClick={onSavePayrollItem}
                variant='primary'
              >
                <Typography color='inherit'>
                  {formatMessage({ id: 'save' })}
                </Typography>
              </Button>
            </CardActions>
          </Card>
        )}
      </Dialog>

      {/* Item Update Dialog. Mobile */}
      <Dialog
        onClose={onCloseUpdateItemDialog}
        open={!!state.editPayrollItemId && isMobile}
      >
        {isFetchingPayrollItemDetails && (
          <Loading variant="loading" />
        )}
        {isFailedUpdatePayrollItem && (
          <Card variant="error">
            <CardTitle>
              <Typography color="error">
                {formatMessage({ id: 'loading.errorUpdatePayroll' })}
              </Typography>
            </CardTitle>
          </Card>
        )}
        {isFinishedPayrollItemDetails && (
          <Card variant='edit'>
            <CardTitle>
              <div>
                <Typography variant='title'>
                  {formatMessage({ id: 'edit.title' })}
                </Typography>
                {!isFailedPayrollItemDetails && (
                  <Typography color='secondary'>
                    {formatMessage(
                      { id: 'edit.subtitle' },
                      {
                        compensationFor: itemDetails.compensationFor,
                        contractor: getContractorName(itemDetails.id),
                      }
                    )}
                  </Typography>
                )}
              </div>
              <IconButton
                disableHoverSpace
                onClick={onCloseUpdateItemDialog}
              >
                <IconClose size={24}/>
              </IconButton>
            </CardTitle>
            <CardContent>
              {isFailedPayrollItemDetails && (
                <Typography>
                  {formatMessage({ id: 'loading.error' })}
                </Typography>
              )}
              {!isFailedPayrollItemDetails && (
                <div className={classes.dialogContainerMobile}>
                  <div className={classes.dialogContentMobile}>
                    <Typography
                      color="secondary"
                      variant="subtitle"
                    >
                      {formatMessage({ id: 'hours.source' })}
                    </Typography>
                    <Typography color='secondary'>
                      {formatNumber(itemDetails.sourceHours)}
                    </Typography>
                  </div>
                  <div className={classes.dialogContentMobile}>
                    <Typography
                      color="secondary"
                      variant="subtitle"
                    >
                      {formatMessage({ id: 'hours.worked' })}
                    </Typography>
                    <TextField
                      AdornmentEnd={
                        state.validationErrors
                          .includes(errorTypes.INVALID_FIELD_HOURS) && (
                          <Tooltip
                            arrow
                            placement="top"
                            title={formatMessage({
                              id: `validationError.${errorTypes
                                .INVALID_FIELD_HOURS}`,
                            })}
                          >
                            <IconError
                              color={theme.colors.redDark}
                              size={20}
                            />
                          </Tooltip>
                        )
                      }
                      disabled={isFetchingUpdatePayrollItem}
                      fullWidth
                      inputType='number'
                      isError={state.validationErrors
                        .includes(errorTypes.INVALID_FIELD_HOURS)}
                      onChange={({ target }) => setState({
                        ...state,
                        payrollItemHours: target.value,
                      })}
                      placeholder={formatMessage({
                        id: 'hours.worked',
                      })}
                      size='small'
                      value={state.payrollItemHours}
                      variant='standard'
                    />
                  </div>
                  {!isNaN(itemDetails.projectInvoiceHours) && (
                    <div className={classes.dialogContentMobile}>
                      <Typography
                        color="secondary"
                        variant="subtitle"
                      >
                        {formatMessage({ id: 'hours.invoice' })}
                      </Typography>
                      <div className={classes.valueContainer}>
                        <Typography color='secondary'>
                          {state.syncWithInvoice
                            ? formatNumber(state.payrollItemHours)
                            : formatNumber(itemDetails.projectInvoiceHours)}
                        </Typography>
                        <div className={classes.checkbox}>
                          <Checkbox
                            checked={state.syncWithInvoice}
                            disableHoverSpace
                            onChange={({ target }) => setState({
                              ...state,
                              syncWithInvoice: target.checked,
                            })}
                          />
                          <Typography
                            color="secondary"
                            variant="caption"
                          >
                            {formatMessage({ id: 'sync' })}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </CardContent>
            <div/>
            <CardActions>
              <Button
                onClick={onCloseUpdateItemDialog}
                variant='secondary'
              >
                <Typography color='inherit'>
                  {formatMessage({ id: 'cancel' })}
                </Typography>
              </Button>
              <Button
                disabled={isFailedPayrollItemDetails}
                isLoading={isFetchingUpdatePayrollItem}
                onClick={onSavePayrollItem}
                variant='primary'
              >
                <Typography color='inherit'>
                  {formatMessage({ id: 'save' })}
                </Typography>
              </Button>
            </CardActions>
          </Card>
        )}
      </Dialog>

      {/* Delete Dialog */}
      <Dialog
        onClose={onCloseDeleteDialog}
        open={!!state.payrollIdToDelete}
      >
        <Card>
          <CardTitle>
            <Typography variant="title">
              {formatMessage({ id: 'delete.title' })}
            </Typography>
            <IconButton
              disableHoverSpace
              onClick={onCloseDeleteDialog}
            >
              <IconClose size={24} />
            </IconButton>
          </CardTitle>
          <CardContent>
            <Typography color="secondary">
              {formatMessage({ id: 'delete.subtitle' })}
            </Typography>
          </CardContent>
          <div />
          <CardActions>
            <Button
              onClick={onCloseDeleteDialog}
              variant='secondary'
            >
              <Typography color='inherit'>
                {formatMessage({ id: 'cancel' })}
              </Typography>
            </Button>
            <Button
              isLoading={isFetchingDeletePayroll}
              onClick={() => {
                dispatch(actionsSetup.clearAfterFetchStatus());
                dispatch(actionsPayrolls.fetchDeletePayroll({
                  contractTypeId: getValidContractTypeId(
                    state.contractTypeId
                  ),
                  month: state.month,
                  payrollId: state.payrollActionsId,
                  year: state.year,
                }));
                onCloseDeleteDialog();
              }}
              variant='primary'
            >
              <Typography color='inherit'>
                {formatMessage({ id: 'delete' })}
              </Typography>
            </Button>
          </CardActions>
        </Card>
      </Dialog>

      {/* ALERTS */}
      <Snackbar
        autoHide
        onClose={() => dispatch(actionsSetup.clearAfterFetchStatus())}
        open={showSuccessAfterSaveAlert}
      >
        <Card variant="success">
          <CardTitle>
            <Typography color="success">
              {successAlertLabel}
            </Typography>
          </CardTitle>
        </Card>
      </Snackbar>
      <Snackbar
        onClose={(event: any, reason: string) => {
          if (reason !== 'clickaway') {
            dispatch(actionsSetup.clearAfterFetchStatus());
          }
        }}
        open={showErrorAfterSaveAlert}
      >
        <Card variant="error">
          <CardTitle>
            <Typography color="error">
              {errorMsg || errorAlertLabel}
            </Typography>
            <IconButton
              disableHoverSpace
              onClick={() => dispatch(actionsSetup.clearAfterFetchStatus())}
            >
              <IconClose size={24}/>
            </IconButton>
          </CardTitle>
        </Card>
      </Snackbar>
    </div>
  );
}

export default Payrolls;
