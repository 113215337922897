import {
  ERROR_DELETE_PAYROLL,
  ERROR_RECEIVE_PAYROLL,
  ERROR_RECEIVE_PAYROLL_CHANGE_HISTORY,
  ERROR_RECEIVE_PAYROLL_ITEM,
  ERROR_RECEIVE_PAYROLLS,
  ERROR_REFRESH_PAYROLL,
  ERROR_REFRESH_PAYROLLS,
  ERROR_RECEIVE_SENT_INVOICES,
  ERROR_UPDATE_PAYROLL_ITEM,
  ERROR_UPDATE_PAYROLL_STATUS,
  RECEIVE_PAYROLL,
  RECEIVE_PAYROLL_CHANGE_HISTORY,
  RECEIVE_PAYROLL_ITEM,
  RECEIVE_PAYROLLS,
  REQUEST_DELETE_PAYROLL,
  REQUEST_PAYROLL,
  REQUEST_PAYROLL_CHANGE_HISTORY,
  REQUEST_PAYROLL_ITEM,
  REQUEST_PAYROLLS,
  REQUEST_REFRESH_PAYROLL,
  REQUEST_REFRESH_PAYROLLS,
  REQUEST_SENT_INVOICES,
  REQUEST_UPDATE_PAYROLL_ITEM,
  REQUEST_UPDATE_PAYROLL_STATUS,
  SUCCESS_DELETE_PAYROLL,
  SUCCESS_REFRESH_PAYROLL,
  SUCCESS_REFRESH_PAYROLLS,
  RECEIVE_SENT_INVOICES,
  SUCCESS_UPDATE_PAYROLL_ITEM,
  SUCCESS_UPDATE_PAYROLL_STATUS,
} from '../constants/actionTypes';

import axios from 'utils/requests';
import config from 'config';

const requestPayrolls = () => ({
  type: REQUEST_PAYROLLS,
});

const receivePayrolls = (response: any) => ({
  payload: response,
  type: RECEIVE_PAYROLLS,
});

const errorReceivePayrolls = () => ({
  type: ERROR_RECEIVE_PAYROLLS,
});

const getPayrolls = ({
  contractTypeId,
  month,
  year,
}: any) => {
  const {
    BASE_URL,
    PAYROLLS_SERVICE,
  } = config;

  return axios
    .post(`${BASE_URL}${PAYROLLS_SERVICE}`,
      {
        contractTypeId,
        month: month,
        year: year,
      })
    .then(({ data }) => data);
};

const fetchPayrolls = ({
  contractTypeId,
  month,
  year,
}: any) => (dispatch: any) => {
  dispatch(requestPayrolls());
  return getPayrolls({
    contractTypeId,
    month,
    year,
  })
    .then(payrolls => dispatch(receivePayrolls(payrolls)))
    .catch(() => dispatch(errorReceivePayrolls()));
};

const requestPayroll = () => ({
  type: REQUEST_PAYROLL,
});

const receivePayroll = (response: any) => ({
  payload: response,
  type: RECEIVE_PAYROLL,
});

const errorReceivePayroll = () => ({
  type: ERROR_RECEIVE_PAYROLL,
});

const getPayroll = ({
  payrollId,
}: any) => {
  const {
    BASE_URL,
    PAYROLLS_SERVICE,
  } = config;

  return axios
    .get(`${BASE_URL}${PAYROLLS_SERVICE}/${payrollId}`)
    .then(({ data }) => data);
};

const fetchPayroll = ({
  payrollId,
}: any) => (dispatch: any) => {
  dispatch(requestPayroll());
  return getPayroll({
    payrollId,
  })
    .then(payroll => dispatch(receivePayroll(payroll)))
    .catch(() => dispatch(errorReceivePayroll()));
};

const requestRefreshPayrolls = () => ({
  type: REQUEST_REFRESH_PAYROLLS,
});

const successRefreshPayrolls = () => ({
  type: SUCCESS_REFRESH_PAYROLLS,
});

const errorRefreshPayrolls = () => ({
  type: ERROR_REFRESH_PAYROLLS,
});

const refreshPayrolls = ({
  month,
  year,
}: any) => {
  const {
    BASE_URL,
    PAYROLLS_SERVICE,
  } = config;

  return axios
    .post(`${BASE_URL}${PAYROLLS_SERVICE}/generate/${year}/${month}`)
    .then(({ data }) => data);
};

const fetchRefreshPayrolls = ({
  contractTypeId,
  month,
  year,
}: any) => (dispatch: any) => {
  dispatch(requestRefreshPayrolls());
  return refreshPayrolls({
    month,
    year,
  }).then(() => {
    dispatch(successRefreshPayrolls());
    return dispatch(fetchPayrolls({
      contractTypeId,
      month,
      year,
    }));
  }).catch(() => dispatch(errorRefreshPayrolls()));
};

const requestUpdPayrollStatus = () => ({
  type: REQUEST_UPDATE_PAYROLL_STATUS,
});

const successUpdPayrollStatus = (response: any) => ({
  payload: response,
  type: SUCCESS_UPDATE_PAYROLL_STATUS,
});

const errorUpdPayrollStatus = (message: any) => ({
  payload: message,
  type: ERROR_UPDATE_PAYROLL_STATUS,
});

const updatePayrollStatus = ({
  payrollId,
  status,
}: any) => {
  const {
    BASE_URL,
    PAYROLLS_SERVICE,
  } = config;

  return axios
    .put(`${BASE_URL}${PAYROLLS_SERVICE}/${payrollId}/status/${status}`)
    .then(({ data }) => data);
};

const fetchUpdatePayrollStatus = ({
  payrollId,
  status,
}: any) => (dispatch: any) => {
  dispatch(requestUpdPayrollStatus());
  return updatePayrollStatus({
    payrollId,
    status,
  }).then(payroll => dispatch(successUpdPayrollStatus(payroll)))
    .catch((error: any) => dispatch(errorUpdPayrollStatus(error)));
};

const requestPayrollItemDetails = () => ({
  type: REQUEST_PAYROLL_ITEM,
});

const receivePayrollItemDetails = (response: any) => ({
  payload: response,
  type: RECEIVE_PAYROLL_ITEM,
});

const errorReceivePayrollItemDetails = () => ({
  type: ERROR_RECEIVE_PAYROLL_ITEM,
});

const getPayrollItemDetails = ({
  itemId,
  payrollId,
}: any) => {
  const {
    BASE_URL,
    PAYROLLS_SERVICE,
  } = config;

  return axios
    .get(`${BASE_URL}${PAYROLLS_SERVICE}/${payrollId}/item/${itemId}`)
    .then(({ data }) => data);
};

const fetchPayrollItemDetails = ({
  itemId,
  payrollId,
}: any) => (dispatch: any) => {
  dispatch(requestPayrollItemDetails());
  return getPayrollItemDetails({
    itemId,
    payrollId,
  }).then(itemDetails => dispatch(receivePayrollItemDetails(itemDetails)))
    .catch(() => dispatch(errorReceivePayrollItemDetails()));
};

const requestUpdPayrollItem = () => ({
  type: REQUEST_UPDATE_PAYROLL_ITEM,
});

const successUpdPayrollItem = (response: any) => ({
  payload: response,
  type: SUCCESS_UPDATE_PAYROLL_ITEM,
});

const errorUpdPayrollItem = () => ({
  type: ERROR_UPDATE_PAYROLL_ITEM,
});

const updatePayrollItem = ({
  hours,
  itemId,
  payrollId,
  syncWithInvoice,
}: any) => {
  const {
    BASE_URL,
    PAYROLLS_SERVICE,
  } = config;

  return axios
    .put(`${BASE_URL}${PAYROLLS_SERVICE}/${payrollId}/item/${itemId}`, {
      hours,
      syncWithInvoice,
    }).then(({ data }) => data);
};

const fetchUpdatePayrollItem = ({
  hours,
  itemId,
  payrollId,
  syncWithInvoice,
}: any) => (dispatch: any) => {
  dispatch(requestUpdPayrollItem());
  return updatePayrollItem({
    hours,
    itemId,
    payrollId,
    syncWithInvoice,
  }).then(payroll => dispatch(successUpdPayrollItem(payroll)))
    .catch(() => dispatch(errorUpdPayrollItem()));
};

const requestRefreshPayroll = () => ({
  type: REQUEST_REFRESH_PAYROLL,
});

const successRefreshPayroll = (response: any) => ({
  payload: response,
  type: SUCCESS_REFRESH_PAYROLL,
});

const errorRefreshPayroll = () => ({
  type: ERROR_REFRESH_PAYROLL,
});

const refreshPayroll = ({
  payrollId,
}: any) => {
  const {
    BASE_URL,
    PAYROLLS_SERVICE,
  } = config;

  return axios
    .put(`${BASE_URL}${PAYROLLS_SERVICE}/refresh/${payrollId}`)
    .then(({ data }) => data);
};

const fetchRefreshPayroll = ({
  payrollId,
}: any) => (dispatch: any) => {
  dispatch(requestRefreshPayroll());
  return refreshPayroll({
    payrollId,
  }).then(payroll => dispatch(successRefreshPayroll(payroll)))
    .catch(() => dispatch(errorRefreshPayroll()));
};

const requestChangeHistory = () => ({
  type: REQUEST_PAYROLL_CHANGE_HISTORY,
});

const receiveChangeHistory = (response: any) => ({
  payload: response,
  type: RECEIVE_PAYROLL_CHANGE_HISTORY,
});

const errorReceiveChangeHistory = () => ({
  type: ERROR_RECEIVE_PAYROLL_CHANGE_HISTORY,
});

const getChangeHistory = ({
  payrollId,
}: any) => {
  const {
    BASE_URL,
    PAYROLLS_SERVICE,
  } = config;

  return axios
    .get(`${BASE_URL}${PAYROLLS_SERVICE}/${payrollId}/history`)
    .then(({ data }) => data);
};

const fetchChangeHistory = ({
  payrollId,
}: any) => (dispatch: any) => {
  dispatch(requestChangeHistory());
  return getChangeHistory({ payrollId })
    .then(changes => dispatch(receiveChangeHistory(changes)))
    .catch(() => dispatch(errorReceiveChangeHistory()));
};

const requestDeletePayroll = () => ({
  type: REQUEST_DELETE_PAYROLL,
});

const successDeletePayroll = (response: any) => ({
  payload: response,
  type: SUCCESS_DELETE_PAYROLL,
});

const errorDeletePayroll = () => ({
  type: ERROR_DELETE_PAYROLL,
});

const deletePayrollById = ({
  payrollId,
}: any) => {
  const {
    BASE_URL,
    PAYROLLS_SERVICE,
  } = config;
  return axios
    .delete(`${BASE_URL}${PAYROLLS_SERVICE}/${payrollId}`)
    .then(({ data }) => data);
};

const fetchDeletePayroll = ({
  contractTypeId,
  month,
  year,
  payrollId,
}: any) => (dispatch: any) => {
  dispatch(requestDeletePayroll());
  return deletePayrollById({
    payrollId,
  }).then(() => (
    getPayrolls({ contractTypeId, month, year })
      .then((payrolls) => dispatch(successDeletePayroll(payrolls)))
      .catch(() => dispatch(errorReceivePayrolls()))
  )).catch(() => dispatch(errorDeletePayroll()));
};

const requestSendConfirmedInvoices = () => ({
  type: REQUEST_SENT_INVOICES,
});

const successSendConfirmedInvoices =
  (statusResponse: any, withAlert: boolean) => ({
    payload: { statusResponse, withAlert },
    type: RECEIVE_SENT_INVOICES,
  });

const errorSendConfirmedInvoices = (message: string) => ({
  payload: message,
  type: ERROR_RECEIVE_SENT_INVOICES,
});

const fetchSendingInvoicesStatus =
  (withAlert: boolean = true) => (dispatch: any) => {
    dispatch(requestSendConfirmedInvoices());
    return refreshSendingStatus().then(({ data } : any) =>
      dispatch(successSendConfirmedInvoices(data, withAlert)))
      .catch(error =>
        dispatch(errorSendConfirmedInvoices(error.response?.data?.message)));
  };

const refreshSendingStatus = () => {
  const {
    BASE_URL,
    PAYROLLS_SERVICE,
  } = config;
  return axios.get(`${BASE_URL}${PAYROLLS_SERVICE}/sendingStatus`);
};

const exportFunctions = {
  fetchChangeHistory,
  fetchDeletePayroll,
  fetchPayroll,
  fetchPayrollItemDetails,
  fetchPayrolls,
  fetchRefreshPayroll,
  fetchRefreshPayrolls,
  fetchSendingInvoicesStatus,
  fetchUpdatePayrollItem,
  fetchUpdatePayrollStatus,
};

export default exportFunctions;
