import authoritiesUI from '../constants/authoritiesUI';
import ContractTypesPage from '../pages/contractTypes';
import PageContainer from '../components/PageContainer';
import PageAccessValidator from '../components/PageAccessValidator';

const TaxTypes = () => (
  <PageContainer>
    <PageAccessValidator neededAuthorities={authoritiesUI.SETTINGS_FINANCES}>
      <ContractTypesPage />
    </PageAccessValidator>
  </PageContainer>
);

export default TaxTypes;
