import { combineReducers } from 'redux';

import contractors from './contractors';
import contractTypes from './contractTypes';

const rootReducer = combineReducers({
  contractTypes,
  contractors,
});

export default rootReducer;
