import {
  ERROR_RECEIVE_REGISTRY_FORMS,
  RECEIVE_REGISTRY_FORMS,
  REQUEST_REGISTRY_FORMS,
} from '../constants/actionTypes';

import axios from 'utils/requests';
import config from 'config';

const requestRegistryForms = () => ({
  type: REQUEST_REGISTRY_FORMS,
});

const receiveRegistryForms = (response: any) => ({
  payload: response,
  type: RECEIVE_REGISTRY_FORMS,
});

const errorReceiveRegistryForms = () => ({
  type: ERROR_RECEIVE_REGISTRY_FORMS,
});

const getRegistryForms = () => {
  const {
    BASE_URL,
    CONTRACT_TYPE_SERVICE,
  } = config;

  return axios
    .get(`${BASE_URL}${CONTRACT_TYPE_SERVICE}/registry-forms`)
    .then(({ data }) => data);
};

const fetchRegistryForms = () => (dispatch: any) => {
  dispatch(requestRegistryForms());

  return getRegistryForms()
    .then(forms => dispatch(receiveRegistryForms(forms)))
    .catch(() => dispatch(errorReceiveRegistryForms()));
};

const exportFunctions = {
  fetchRegistryForms,
};

export default exportFunctions;
