import {
  ERROR_RECEIVE_TAXES,
  RECEIVE_TAXES,
  REQUEST_TAXES,
  REQUEST_SAVE_TAXES,
  SUCCESS_SAVE_TAXES,
  ERROR_SAVE_TAXES,
} from '../constants/actionTypes';

type Action = {
  payload: any,
  type: string,
}

const initialState = {
  isFailed: false,
  isFailedSave: false,
  isFetching: false,
  isFetchingSave: false,
  list: null,
};

const convertTax = (tax: any) => ({
  month: tax.month,
  taxTypeId: tax.taxTypeId,
  value: tax.value,
  year: tax.year,
});

const convertTaxes = (input: any) => input
  .map((tax: any) => convertTax(tax));

export default function Reducer(state = initialState, action: Action) {
  switch (action.type) {
    case REQUEST_TAXES: {
      return {
        ...state,
        isFailed: false,
        isFetching: true,
        list: null,
      };
    }

    case RECEIVE_TAXES: {
      const list = convertTaxes(action.payload);
      return {
        ...state,
        isFailed: false,
        isFetching: false,
        list,
      };
    }

    case ERROR_RECEIVE_TAXES: {
      return {
        ...state,
        isFailed: true,
        isFetching: false,
      };
    }

    case REQUEST_SAVE_TAXES: {
      return {
        ...state,
        isFailedSave: false,
        isFetchingSave: true,
      };
    }

    case SUCCESS_SAVE_TAXES: {
      const list = convertTaxes(action.payload);
      return {
        ...state,
        isFailedSave: false,
        isFetchingSave: false,
        list,
      };
    }

    case ERROR_SAVE_TAXES: {
      return {
        ...state,
        isFailedSave: true,
        isFetchingSave: false,
      };
    }

    default: return state;
  }
};
