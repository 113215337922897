import {
  ERROR_RECEIVE_TYPES,
  RECEIVE_TYPES,
  REQUEST_TYPES,
  RESET_REDUCERS_DATA,
} from '../constants/actionTypes';

type Action = {
  payload: any,
  type: string,
}

const initialState = {
  isFailed: false,
  isFetching: false,
  list: [],
};

const convertType = (item: any) => ({
  active: item.active,
  id: item.id,
  measureType: item.measureType,
  name: item.name,
});

const convertTypes = (types: any) => types
  .map((item: any) => convertType(item));

export default function Reducer(state = initialState, action: Action) {
  switch (action.type) {
    case REQUEST_TYPES: {
      return {
        ...state,
        isFailed: false,
        isFetching: true,
      };
    }

    case RECEIVE_TYPES: {
      const list = convertTypes(action.payload);
      return {
        ...state,
        isFailed: false,
        isFetching: false,
        list,
      };
    }

    case ERROR_RECEIVE_TYPES: {
      return {
        ...state,
        isFailed: true,
        isFetching: false,
      };
    }

    case RESET_REDUCERS_DATA: return initialState;

    default: return state;
  }
}
