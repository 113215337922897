export const REQUEST_TAXES = 'REQUEST_TAXES';
export const RECEIVE_TAXES = 'RECEIVE_TAXES';
export const ERROR_RECEIVE_TAXES = 'ERROR_RECEIVE_TAXES';

export const REQUEST_SAVE_TAXES = 'REQUEST_SAVE_TAXES';
export const SUCCESS_SAVE_TAXES = 'SUCCESS_SAVE_TAXES';
export const ERROR_SAVE_TAXES = 'ERROR_SAVE_TAXES';

export const REQUEST_TYPES = 'REQUEST_TYPES';
export const RECEIVE_TYPES = 'RECEIVE_TYPES';
export const ERROR_RECEIVE_TYPES = 'ERROR_RECEIVE_TYPES';

export const RESET_REDUCERS_DATA = 'RESET_REDUCERS_DATA';
