export const REQUEST_CONTRACT_TYPES = 'REQUEST_CONTRACT_TYPES';
export const RECEIVE_CONTRACT_TYPES = 'RECEIVE_CONTRACT_TYPES';
export const ERROR_RECEIVE_CONTRACT_TYPES = 'ERROR_RECEIVE_CONTRACT_TYPES';

export const REQUEST_CREATE_CONTRACT_TYPE = 'REQUEST_CREATE_CONTRACT_TYPE';
export const SUCCESS_CREATE_CONTRACT_TYPE = 'SUCCESS_CREATE_CONTRACT_TYPE';
export const ERROR_CREATE_CONTRACT_TYPE = 'ERROR_CREATE_CONTRACT_TYPE';

export const REQUEST_UPDATE_CONTRACT_TYPE = 'REQUEST_UPDATE_CONTRACT_TYPE';
export const SUCCESS_UPDATE_CONTRACT_TYPE = 'SUCCESS_UPDATE_CONTRACT_TYPE';
export const ERROR_UPDATE_CONTRACT_TYPE = 'ERROR_UPDATE_CONTRACT_TYPE';

export const REQUEST_DELETE_CONTRACT_TYPE = 'REQUEST_DELETE_CONTRACT_TYPE';
export const SUCCESS_DELETE_CONTRACT_TYPE = 'SUCCESS_DELETE_CONTRACT_TYPE';
export const ERROR_DELETE_CONTRACT_TYPE = 'ERROR_DELETE_CONTRACT_TYPE';

export const REQUEST_TAX_TYPES = 'REQUEST_TAX_TYPES';
export const RECEIVE_TAX_TYPES = 'RECEIVE_TAX_TYPES';
export const ERROR_RECEIVE_TAX_TYPES = 'ERROR_RECEIVE_TAX_TYPES';

export const REQUEST_INVOICE_FORMS = 'REQUEST_INVOICE_FORMS';
export const RECEIVE_INVOICE_FORMS = 'RECEIVE_INVOICE_FORMS';
export const ERROR_RECEIVE_INVOICE_FORMS = 'ERROR_RECEIVE_INVOICE_FORMS';

export const REQUEST_REGISTRY_FORMS = 'REQUEST_REGISTRY_FORMS';
export const RECEIVE_REGISTRY_FORMS = 'RECEIVE_REGISTRY_FORMS';
export const ERROR_RECEIVE_REGISTRY_FORMS = 'ERROR_RECEIVE_REGISTRY_FORMS';

export const RESET_REDUCERS_DATA = 'RESET_REDUCERS_DATA';
