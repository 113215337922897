import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { makeStyles } from 'tss-react/mui';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import Breadcrumb from 'components/Breadcrumb';
import Breadcrumbs from 'components/Breadcrumbs';
import Button from 'components/Button';
import Card from 'components/Card';
import CardActions from 'components/CardActions';
import CardContent from 'components/CardContent';
import CardTitle from 'components/CardTitle';
import Divider from 'components/Divider';
import Hover from 'components/Hover';
import IconButton from 'components/IconButton';
import IconCopy from 'components/icons/Copy';
import IconClose from 'components/icons/Close';
import IconDelete from 'components/icons/Delete';
import IconEdit from 'components/icons/Edit';
import IconError from 'components/icons/Error';
import IconInfo from 'components/icons/Info';
import IconRestore from 'components/icons/Restore';
import Loading from 'components/Loading';
import MenuItem from 'components/MenuItem';
import moment from 'moment/moment';
import Popover from 'components/Popover';
import Select from 'components/Select';
import Snackbar from 'components/Snackbar';
import Tabs from 'components/Tabs';
import Tab from 'components/Tab';
import Table from 'components/Table';
import TableBody from 'components/TableBody';
import TableCell from 'components/TableCell';
import TableCellHead from 'components/TableCellHead';
import TableHead from 'components/TableHead';
import TableRow from 'components/TableRow';
import TextField from 'components/TextField';
import Tooltip from 'components/Tooltip';
import Typography from 'components/Typography';
import useIsMobile from 'hooks/useIsMobile';
import useLocationSearch from 'hooks/useLocationSearch';
import useTheme from 'hooks/useTheme';

import * as pages from 'constants/pages';
import actionsProject from '../actions/project';
import actionsSetup from '../actions/setup';
import orderedCurrencies, * as currencies from 'constants/currencies';
import pagesURLs from 'constants/pagesURLs';
import rateTypesList from '../constants/rateTypes';

const getClasses = makeStyles<any>()((_, theme: any) => ({
  bar: {
    display: 'flex',
  },
  cell: {
    minWidth: '40%',
    width: '40%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(2)}px`,
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(2)}px`,
    padding: `${theme.spacing(2)}px 0px`,
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(2)}px`,
  },
  infoContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(2.5)}px`,
  },
  periodicity: {
    alignItems: 'center',
    display: 'flex',
    gap: `${theme.spacing(2)}px`,
    width: '100%',
  },
  planedPercent: {
    width: '45px',
  },
  planedPercentWithAdornment: {
    width: '60px',
  },
  popoverCell: {
    minWidth: '25%',
    width: '25%',
  },
  popoverContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(3.5)}px`,
    minWidth: '300px',
  },
  popoverPeriodicity: {
    alignItems: 'center',
    display: 'flex',
    gap: `${theme.spacing(2)}px`,
    width: '100%',
  },
  rate: {
    width: '200px',
  },
  row: {
    alignItems: 'center',
    display: 'flex',
    gap: `${theme.spacing(1)}px`,
    minHeight: '20px',
  },
  rowMobile: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(1)}px`,
  },
  rowPopover: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(0.5)}px`,
    padding: `0px ${theme.spacing(2)}px`,
  },
  selectMonth: {
    width: '65%',
  },
  selectYear: {
    width: '35%',
  },
  tableCell: {
    alignItems: 'center',
    display: 'flex',
    height: '16px',
  },
  tableCellAction: {
    alignItems: 'center',
    display: 'flex',
    gap: `${theme.spacing(0.5)}px`,
    height: '16px',
    justifyContent: 'flex-end',
  },
  tableCellHead: {
    display: 'flex',
    gap: `${theme.spacing(1)}px`,
  },
  totalsContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: `${theme.spacing(1)}px`,
  },
  totalsRowContainer: {
    display: 'flex',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  totalsRowContainerLeft: {
    display: 'flex',
    flex: 1,
    minWidth: '290px',
  },
  totalsRowContainerRight: {
    display: 'flex',
    width: '500px',
  },
  totalsRowContainerRightInner: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: `${theme.spacing(0.5)}px`,
    width: '230px',
  },
  totalsTooltip: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(1)}px`,
  },
  totalsTooltipContent: {
    display: 'flex',
    gap: `${theme.spacing(1)}px`,
    justifyContent: 'space-between',
  },
}));

const TABS = {
  DETAILS: 'DETAILS',
  RATES: 'RATES',
};

const TABS_LIST = [
  TABS.RATES,
  TABS.DETAILS,
];

const clientInfoFields: { [key: string]: string } = {
  ADDRESS: 'ADDRESS',
  ADDRESS_POL: 'ADDRESS_POL',
  BANK_ADDRESS: 'BANK_ADDRESS',
  BANK_NAME: 'BANK_NAME',
  BANK_NUMBER: 'BANK_NUMBER',
  CIF: 'CIF',
  EMAIL: 'EMAIL',
  GENERATE_INVOICE: 'GENERATE_INVOICE',
  INVOICE_PREFIX: 'INVOICE_NUMBER_PREFIX',
  KRS: 'KRS',
  NAME: 'NAME',
  NIP: 'NIP',
  SIGNATORY: 'SIGNATORY',
  SWIFT: 'SWIFT',
  TAX_ID: 'TAX_ID',
};

const TABLE_COLUMNS = {
  NAME: 'NAME',
  PLANED_PERCENT: 'PLANED_PERCENT',
  RANGE: 'RANGE',
  RATE: 'RATE',
  RATE_CURRENCY: 'RATE_CURRENCY',
  TITLE: 'TITLE',
  TYPE: 'TYPE',
};

const changeMemberFieldByColumnName = {
  [TABLE_COLUMNS.TITLE]: ({ member, value }: any) => ({
    ...member,
    title: value,
  }),
  [TABLE_COLUMNS.PLANED_PERCENT]: ({ member, value }: any) => ({
    ...member,
    workload: value,
  }),
  [TABLE_COLUMNS.RANGE]: ({ member, value }: any) => ({
    ...member,
    range: value,
  }),
  [TABLE_COLUMNS.RATE]: ({ member, value }: any) => ({
    ...member,
    sum: value,
  }),
  [TABLE_COLUMNS.RATE_CURRENCY]: ({ member, value }: any) => ({
    ...member,
    currency: value,
  }),
  [TABLE_COLUMNS.TYPE]: ({ member, value }: any) => ({
    ...member,
    rateType: value,
  }),
};

const ACTIONS_COLUMN_IDX = 6;
const CURRENT_YEAR = moment().year();
const DATE_FORMAT = 'YYYY-MM-DD';
const DEFAULT_COLUMN_IDX = 1;
const ICON_SIZE = 20;
const ICON_SIZE_CAPTION = 16;
const INVOICE_PREFIX_CHARS_LIMIT = 3;
const PLANED_PERCENT_CHARS_LIMIT = 3;
const PLANED_PERCENT_DEFAULT_VALUE = 100;
const PLANED_PERCENT_MAX_VALUE = 200;
const PLANED_PERCENT_MIN_VALUE = 0;
const YEARS_FORWARD = 3;
const validationErrorTypes = {
  INVOICE_PREFIX_EMPTY: 'INVOICE_PREFIX_EMPTY',
  PLANED_PERCENT_EXCEED_LIMITS: 'PLANED_PERCENT_EXCEED_LIMITS',
};

const months = Array
  .from(new Array(12).keys())
  .map(key => key + 1);

const years = [
  ...Array
    .from(new Array(YEARS_FORWARD).keys())
    .map(index => CURRENT_YEAR + index)
    .reverse(),
];

const isNumber = (value: string) => /^[0-9]+$/.test(value);

const getValidationErrorsToMemberIds = (members: any) => members
  .reduce((acc: any, member: any) => {
    if (typeof member.workload === 'number'
      && (member.workload < PLANED_PERCENT_MIN_VALUE
        || member.workload > PLANED_PERCENT_MAX_VALUE
      )
    ) {
      acc[member.id] = (acc[member.id] || [])
        .concat(validationErrorTypes.PLANED_PERCENT_EXCEED_LIMITS);
    }
    return acc;
  }, {});

const convertToMoment = (month: number, year: number) => (!!month && !!year)
  ? moment([
    year,
    month - 1,
    1,
  ])
  : null;

const formatDate = (date: any) => date
  ? date.format(DATE_FORMAT)
  : null;

const convertMembersToBE = (members: any) => members
  .map((member: any) => ({
    contractorId: member.contractorId,
    currency: member.currency,
    dateRange: {
      from: formatDate(convertToMoment(
        member.range?.fromMonth,
        member.range?.fromYear
      )),
      to: formatDate(convertToMoment(
        member.range?.toMonth,
        member.range?.toYear
      )),
    },
    deleted: member.deleted,
    id: member.isTemporaryCopy ? null : member.id,
    rateType: member.rateType,
    sum: member.sum,
    title: member.title,
    workload: member.workload,
  }));

const formatCurrency = (value: any) => (value == null || value === '')
  ? value
  : Number(value)
    .toFixed(2)
    // find number that is followed by a set of 3 numbers and insert a space
    .replace(/\d(?=(\d{3})+\.)/g, '$& ')
    .replace('.', ',');

function ProjectMembersAndRates() {
  const { theme } = useTheme();
  const { classes } = getClasses(theme);
  const { formatMessage } = useIntl();
  const { lang } = useLocationSearch();
  const { projectId } = useParams();
  const isMobile = useIsMobile();
  const dispatch: Dispatch<any> = useDispatch();

  const {
    errorMessage,
    isFailed: isFailedProject,
    isFailedSave: isFailedSaveProject,
    isFailedUpdate: isFailedUpdateDetails,
    isFetching: isFetchingProject,
    isFetchingSave: isFetchingSaveProject,
    isFetchingUpdate: isFetchingUpdateDetails,
    isSuccessSave: isSuccessSaveProject,
    isSuccessUpdate: isSuccessUpdateDetails,
    project,
  } = useSelector(({ project: reducerProject }: any) => reducerProject);

  const [state, setState] = useState({
    compactColumnIndex: DEFAULT_COLUMN_IDX,
    customerAddress: '',
    customerAddressPol: '',
    customerBankAddress: '',
    customerBankName: '',
    customerBankNumber: '',
    customerCIF: '',
    customerEmail: '',
    customerKRS: '',
    customerNIP: '',
    customerName: '',
    customerSignatory: '',
    customerSwift: '',
    customerTaxId: '',
    detailsTabValidationErrors: [] as any,
    editMemberPeriodicityId: null,
    generateInvoice: false,
    invoiceNumberPrefix: '',
    isEditDetails: false,
    isEditMembers: false,
    members: [] as any,
    periodicityAnchor: null,
    showAfterSaveAlert: false,
    tab: TABS.RATES,
    validationErrorsToRowIds: {} as any,
  });

  const getDetailsTabValidationErrors = () => {
    const errors = [] as any;
    if (state.generateInvoice && !state.invoiceNumberPrefix?.length) {
      errors.push(validationErrorTypes.INVOICE_PREFIX_EMPTY);
    }
    return errors;
  };

  const isFailed = isFailedProject;
  const isFetching = isFetchingProject;

  const getPeriodicityLabel = ({
    fromMonth,
    fromYear,
    short = false,
    toMonth,
    toYear,
    withDuration = false,
  }: any) => {
    const isFrom = !!fromMonth && !!fromYear;
    const isTo = !!toMonth && !!toYear;

    if (isFrom && !isTo) {
      return formatMessage(
        { id: 'periodicity.from' },
        {
          fromMonth: formatMessage({
            id: `month.${fromMonth}${short ? '.short' : ''}`,
          }),
          fromYear: fromYear,
        });
    }

    if (!isFrom && isTo) {
      return formatMessage(
        { id: 'periodicity.to' },
        {
          toMonth: formatMessage({
            id: `month.${toMonth}${short ? '.short' : ''}`,
          }),
          toYear: toYear,
        });
    }

    if (isFrom && isTo) {
      if (withDuration) {
        const duration = moment(convertToMoment(toMonth, toYear))
          .diff(moment(convertToMoment(fromMonth, fromYear)), 'months') + 1;
        if (duration <= 0) {
          return formatMessage({ id: 'periodicity.negativeWarning' });
        }
        return formatMessage(
          { id: 'periodicity.duration' },
          { duration: duration }
        );
      } else {
        if (fromMonth === toMonth && fromYear === toYear) {
          return formatMessage(
            { id: 'periodicity.oneTime' },
            {
              fromMonth: formatMessage({
                id: `month.${fromMonth}${short ? '.short' : ''}`,
              }),
              fromYear: fromYear,
            });
        } else {
          return formatMessage(
            { id: 'periodicity.fromTo' },
            {
              fromMonth: formatMessage({
                id: `month.${fromMonth}${short ? '.short' : ''}`,
              }),
              fromYear: fromYear,
              toMonth: formatMessage({
                id: `month.${toMonth}${short ? '.short' : ''}`,
              }),
              toYear: toYear,
            });
        }
      }
    }

    return state.isEditMembers
      ? formatMessage({ id: 'periodicity.monthly' })
      : '';
  };

  const clientRows = useMemo(() => ({
    [clientInfoFields.ADDRESS]: {
      label: formatMessage({ id: 'client.ADDRESS' }),
      value: project?.address,
    },
    [clientInfoFields.ADDRESS_POL]: {
      label: formatMessage({ id: 'client.ADDRESS_POL' }),
      value: project?.addressPol,
    },
    [clientInfoFields.BANK_ADDRESS]: {
      label: formatMessage({ id: 'client.BANK_ADDRESS' }),
      value: project?.bankAddress,
    },
    [clientInfoFields.BANK_NAME]: {
      label: formatMessage({ id: 'client.BANK_NAME' }),
      value: project?.bankName,
    },
    [clientInfoFields.BANK_NUMBER]: {
      label: formatMessage({ id: 'client.BANK_NUMBER' }),
      value: project?.bankAccountNumber,
    },
    [clientInfoFields.CIF]: {
      label: formatMessage({ id: 'client.CIF' }),
      value: project?.cif,
    },
    [clientInfoFields.EMAIL]: {
      label: formatMessage({ id: 'client.EMAIL' }),
      value: project?.emailPersonal,
    },
    [clientInfoFields.GENERATE_INVOICE]: {
      label: formatMessage({ id: 'client.GENERATE_INVOICE' }),
      value: project?.isNeedGenerateInvoice,
    },
    [clientInfoFields.INVOICE_PREFIX]: {
      label: formatMessage({ id: 'client.INVOICE_PREFIX' }),
      value: project?.invoiceNumberPrefix,
    },
    [clientInfoFields.KRS]: {
      label: formatMessage({ id: 'client.KRS' }),
      value: project?.krs,
    },
    [clientInfoFields.NAME]: {
      label: formatMessage({ id: 'client.NAME' }),
      value: project?.name,
    },
    [clientInfoFields.NIP]: {
      label: formatMessage({ id: 'client.NIP' }),
      value: project?.nip,
    },
    [clientInfoFields.SIGNATORY]: {
      label: formatMessage({ id: 'client.SIGNATORY' }),
      value: project?.signatory,
    },
    [clientInfoFields.SWIFT]: {
      label: formatMessage({ id: 'client.SWIFT' }),
      value: project?.bankSwift,
    },
    [clientInfoFields.TAX_ID]: {
      label: formatMessage({ id: 'client.TAX_ID' }),
      value: project?.taxId,
    },
  }), [project, lang]);

  const tableRows = useMemo(() => {
    return state.members
      .map((member: any) => ({
        contractorId: member.contractorId,
        deleted: member.deleted,
        id: member.id,
        [TABLE_COLUMNS.NAME]: {
          label: member.name,
          value: member.name,
        },
        [TABLE_COLUMNS.PLANED_PERCENT]: {
          label: typeof member.workload === 'number'
            ? `${member.workload}%`
            : '',
          value: member.workload,
        },
        [TABLE_COLUMNS.RANGE]: {
          fromMonth: member.range?.fromMonth,
          fromYear: member.range?.fromYear,
          label: getPeriodicityLabel({
            fromMonth: member.range?.fromMonth,
            fromYear: member.range?.fromYear,
            short: true,
            toMonth: member.range?.toMonth,
            toYear: member.range?.toYear,
          }),
          toMonth: member.range?.toMonth,
          toYear: member.range?.toYear,
        },
        [TABLE_COLUMNS.RATE]: {
          label: typeof member.sum === 'number'
            ? `${formatCurrency(member.sum)} ${member.currency}`
            : '',
          value: member.sum,
        },
        [TABLE_COLUMNS.RATE_CURRENCY]: {
          label: member.currency,
          value: member.currency,
        },
        [TABLE_COLUMNS.TITLE]: {
          label: member.title,
          value: member.title,
        },
        [TABLE_COLUMNS.TYPE]: {
          label: member.rateType
            ? formatMessage({ id: `rateType.${member.rateType}` })
            : '',
          value: member.rateType,
        },
      }))
      .sort((row1: any, row2: any) => row1[TABLE_COLUMNS.NAME].label
        .localeCompare(row2[TABLE_COLUMNS.NAME].label));
  }, [state.members]);

  const tableTotals = useMemo(() => (tableRows
    .reduce((acc: any, row: any) => {
      const type = row[TABLE_COLUMNS.TYPE].value;
      const rateValue = row[TABLE_COLUMNS.RATE].value;
      const currency = row[TABLE_COLUMNS.RATE_CURRENCY].value;
      if (!type) {
        return acc;
      }
      const existingIndex = acc.findIndex((entry: any) =>
        entry.type === type && entry.currency === currency);
      if (existingIndex !== -1) {
        acc[existingIndex] = {
          ...acc[existingIndex],
          value: acc[existingIndex].value + rateValue,
        };
      } else {
        acc.push({
          currency,
          type,
          value: rateValue || 0,
        });
      }

      return acc;
    }, [])
  ), [tableRows]);

  const popoverMember = useMemo(() => (state.members
    .find((member: any) => member.id === state.editMemberPeriodicityId)
  ), [
    state.editMemberPeriodicityId,
    state.members,
  ]);

  const getValidationHint = (error: string) => {
    if (validationErrorTypes.PLANED_PERCENT_EXCEED_LIMITS === error) {
      return formatMessage(
        {
          id: `save.error.${validationErrorTypes.PLANED_PERCENT_EXCEED_LIMITS}`,
        },
        {
          max: PLANED_PERCENT_MAX_VALUE,
          min: PLANED_PERCENT_MIN_VALUE,
        }
      );
    }

    if (validationErrorTypes.INVOICE_PREFIX_EMPTY === error) {
      return formatMessage(
        {
          id: `save.error.${validationErrorTypes.INVOICE_PREFIX_EMPTY}`,
        }
      );
    }
  };

  const onCancelEditDetails = () => setState({
    ...state,
    detailsTabValidationErrors: [],
    isEditDetails: false,
    showAfterSaveAlert: false,
  });

  const onCancelEditMembers = () => setState({
    ...state,
    compactColumnIndex: state.compactColumnIndex === ACTIONS_COLUMN_IDX
      ? DEFAULT_COLUMN_IDX
      : state.compactColumnIndex,
    isEditMembers: false,
    members: project?.members || [],
    showAfterSaveAlert: false,
    validationErrorsToRowIds: {},
  });

  const onCloseAlert = () => setState((prevState: any) => ({
    ...prevState,
    showAfterSaveAlert: false,
  }));

  const onStartEditMembers = () => {
    // Get all members with currencies and take currency of the first one
    // basing on knowledge that all members have same currencies.
    const allMembersCurrency = (state.members
      .find((member: any) => !!member.currency) as any)
      ?.currency;

    setState({
      ...state,
      isEditMembers: true,
      members: state.members.map((member: any) => ({
        ...member,
        currency: allMembersCurrency || currencies.USD,
        workload: typeof member.workload === 'number'
          ? member.workload
          : PLANED_PERCENT_DEFAULT_VALUE,
      })) as any,
    });
  };

  const onClickMemberRange = ({
    anchor,
    memberId,
  }: any) => setState({
    ...state,
    editMemberPeriodicityId: memberId,
    periodicityAnchor: anchor,
  });

  const onClosePeriodicityPopover = () => setState({
    ...state,
    editMemberPeriodicityId: null,
    periodicityAnchor: null,
  });

  const onChangeMemberValue = ({
    columnName,
    memberId,
    value,
  }: any) => {
    let members;
    if (columnName === TABLE_COLUMNS.RATE_CURRENCY) {
      members = state.members
        .map((member: any) => changeMemberFieldByColumnName[columnName]({
          member,
          value,
        }));
    } else {
      members = state.members
        .map((member: any) => member.id === memberId
          ? changeMemberFieldByColumnName[columnName]({
            member,
            value,
          })
          : member
        );
    }
    setState({
      ...state,
      members,
    });
  };

  const getRowDeletability = (row: any) => {
    const filtered = state.members
      .filter((member: any) => member.contractorId === row.contractorId)
      .filter((member: any) => !member.deleted);

    return filtered.length > 1;
  };

  const onDuplicateMemberRow = (memberId: any) => {
    const { members } = state;
    const memberToDuplicate = members
      .find((member: any) => member.id === memberId);

    if (memberToDuplicate) {
      const membersWithDuplicate = members.concat({
        ...memberToDuplicate,
        id: `${memberToDuplicate.id}_${crypto.randomUUID()}`,
        isTemporaryCopy: true,
        range: {
          from: null,
          to: null,
        },
      });

      setState({
        ...state,
        members: membersWithDuplicate,
      });
    }
  };

  const onDeleteRestoreMemberRow = (memberId: any) => {
    const members = state.members.map((member: any) => (member.id === memberId
      ? {
        ...member,
        deleted: !member.deleted,
      }
      : member));

    setState({
      ...state,
      members,
    });
  };

  const onSave = () => {
    const validationErrorsToRowIds = getValidationErrorsToMemberIds(state
      .members);
    const hasErrors = Object.keys(validationErrorsToRowIds).length;
    if (!hasErrors) {
      dispatch(actionsProject.fetchSaveProject({
        members: convertMembersToBE(state.members),
        projectId,
      }));
    }
    setState({
      ...state,
      validationErrorsToRowIds,
    });
  };

  const onDetailsTabSave = () => {
    const errors = getDetailsTabValidationErrors();
    if (!errors.length) {
      dispatch(actionsProject
        .fetchUpdateProjectDetails({
          accountNumber: state.customerBankNumber,
          address: state.customerAddress,
          addressPol: state.customerAddressPol,
          bankAddress: state.customerBankAddress,
          bankName: state.customerBankName,
          bankSwiftCode: state.customerSwift,
          cif: state.customerCIF,
          emailPersonal: state.customerEmail,
          fullName: state.customerName,
          invoiceNumberPrefix: state.invoiceNumberPrefix,
          isNeedGenerateInvoice: state.generateInvoice,
          krs: state.customerKRS,
          nip: state.customerNIP,
          projectId,
          signatory: state.customerSignatory,
          taxId: state.customerTaxId,
        }));
    }
    setState({
      ...state,
      detailsTabValidationErrors: errors,
    });
  };

  useEffect(() => {
    if (isSuccessSaveProject || isFailedSaveProject) {
      setState(prevState => ({
        ...prevState,
        isEditMembers: isSuccessSaveProject
          ? false
          : prevState.isEditMembers,
        showAfterSaveAlert: true,
      }));
    }
  }, [isFailedSaveProject, isSuccessSaveProject]);

  useEffect(() => {
    if (isSuccessUpdateDetails || isFailedUpdateDetails) {
      setState(prevState => ({
        ...prevState,
        isEditDetails: isFailedUpdateDetails,
        showAfterSaveAlert: true,
      }));
    }
  }, [isFetchingUpdateDetails]);

  useEffect(() => {
    if (project?.members?.length) {
      setState(prevState => ({
        ...prevState,
        members: project.members,
      }));
    }
  }, [project]);

  useEffect(() => {
    dispatch(actionsProject.fetchProject({
      projectId,
    }));
    return () => {
      dispatch(actionsSetup.resetReducer());
    };
  }, [projectId]);

  return (
    <div className={classes.container}>
      <Breadcrumbs>
        <Breadcrumb
          label={formatMessage({ id: 'clientsAndProjects' })}
          to={{
            pathname: `${pagesURLs[pages.clientsAndProjects]}`,
          }}
          variant="link"
        />
        <Breadcrumb
          label={project?.projectName || ''}
          variant="text"
        />
      </Breadcrumbs>
      {(isFetching || isFailed)
        && (
          <Loading variant={isFailed ? 'error' : 'loading'}>
            {isFailed && (
              <Typography color="secondary" variant="subtitle">
                {formatMessage({ id: 'loading.error' })}
              </Typography>
            )}
          </Loading>
        )}
      {!isFailed
        && !isFetching
        && project?.members
        && !project.members.length
        && (
          <Loading variant="noData">
            <Typography color="secondary" variant="subtitle">
              {formatMessage({ id: 'loading.noData' })}
            </Typography>
          </Loading>
        )}
      {!isFetching
        && !isFailed
        && !!project?.members?.length
        && (
          <div>
            <div className={classes.bar}>
              <Tabs
                value={state.tab}
                onChange={(event, newValue) => setState((prevState: any) => ({
                  ...prevState,
                  isEditDetails: false,
                  isEditMembers: false,
                  members: project?.members || [],
                  showAfterSaveAlert: false,
                  tab: newValue,
                  validationErrorsToRowIds: {},
                }))}
              >
                {TABS_LIST.map(tab => (
                  <Tab
                    disabled={isFetchingSaveProject || isFetchingUpdateDetails}
                    label={formatMessage({ id: `tab.${tab}` })}
                    value={tab}
                  />
                ))}
              </Tabs>
            </div>
            {state.tab === TABS.RATES && (
              <div className={classes.container}>
                <Card>
                  <CardTitle>
                    <div>
                      <Typography
                        color="secondary"
                      >
                        {formatMessage({
                          id: 'members.edit.subtitle',
                        })}
                      </Typography>
                    </div>
                    <div>
                      {state.isEditMembers
                        ? (
                          <Button
                            onClick={onCancelEditMembers}
                            variant='secondary'
                          >
                            <Typography color='inherit'>
                              {formatMessage({ id: 'cancel' })}
                            </Typography>
                          </Button>
                        )
                        : (
                          <Button
                            disabled={isFetchingSaveProject}
                            onClick={onStartEditMembers}
                            variant="primary"
                            startIcon={(
                              <IconEdit
                                color="button"
                                size={ICON_SIZE}
                              />
                            )}
                          >
                            <Typography color="inherit">
                              {formatMessage({ id: 'edit' })}
                            </Typography>
                          </Button>
                        )}
                    </div>
                  </CardTitle>
                </Card>
                <Card
                  disablePaddings
                  variant={state.isEditMembers ? 'edit' : 'paper'}
                >
                  <Table
                    compact={isMobile}
                    compactColumnIndex={state.compactColumnIndex}
                    fixed
                    onChangeCompactColumnIndex={(index) => setState({
                      ...state,
                      compactColumnIndex: index,
                    })}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCellHead compactVariant="static">
                          <Typography
                            color='secondary'
                            noWrap
                            variant='caption'
                          >
                            {formatMessage({
                              id: `tableColumn.${TABLE_COLUMNS.NAME}`,
                            })}
                          </Typography>
                        </TableCellHead>
                        <TableCellHead
                          compactVariant="dynamic"
                          size={!isMobile ? 'mediumField' : undefined}
                        >
                          <Typography
                            color='secondary'
                            noWrap
                            variant='caption'
                          >
                            {formatMessage({
                              id: `tableColumn.${TABLE_COLUMNS.TITLE}`,
                            })}
                          </Typography>
                        </TableCellHead>
                        <TableCellHead
                          compactVariant="dynamic"
                          size={!isMobile ? 'bars' : undefined}
                        >
                          <Typography
                            color='secondary'
                            noWrap
                            variant='caption'
                          >
                            {formatMessage({
                              id: `tableColumn.${TABLE_COLUMNS.PLANED_PERCENT}`,
                            })}
                          </Typography>
                        </TableCellHead>
                        <TableCellHead
                          compactVariant="dynamic"
                          size={!isMobile ? 'smallField' : undefined}
                        >
                          <Typography
                            color='secondary'
                            noWrap
                            variant='caption'
                          >
                            {formatMessage({
                              id: `tableColumn.${TABLE_COLUMNS.TYPE}`,
                            })}
                          </Typography>
                        </TableCellHead>
                        <TableCellHead
                          compactVariant="dynamic"
                          size={!isMobile ? 'smallField' : undefined}
                        >
                          <div className={classes.tableCellHead}>
                            <Typography
                              color="secondary"
                              noWrap
                              variant="caption"
                            >
                              {formatMessage({
                                id: `tableColumn.${TABLE_COLUMNS.RATE}`,
                              })}
                            </Typography>
                            {(
                              !state.isEditMembers
                              && !isMobile
                              && !!tableTotals?.length
                            ) && (
                              <Tooltip
                                title={(
                                  <div className={classes.totalsTooltip}>
                                    <Typography
                                      color="paper"
                                      variant="caption"
                                    >
                                      {formatMessage({ id: 'total' })}
                                    </Typography>
                                    <div>
                                      {tableTotals.map((row: any) => (
                                        <div
                                          className={classes
                                            .totalsTooltipContent}
                                        >
                                          <Typography
                                            color="paper"
                                            variant="caption"
                                          >
                                            {formatMessage({
                                              id: `total.rateType.${row.type}`,
                                            })}
                                          </Typography>
                                          <Typography
                                            color="paper"
                                            variant="caption"
                                          >
                                            {`${row.value} ${row.currency}`}
                                          </Typography>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                )}
                              >
                                <IconInfo size={ICON_SIZE_CAPTION} />
                              </Tooltip>
                            )}
                          </div>
                        </TableCellHead>
                        <TableCellHead
                          compactVariant="dynamic"
                          size={!isMobile ? 'mediumField' : undefined}
                        >
                          <Typography
                            color='secondary'
                            noWrap
                            variant='caption'
                          >
                            {formatMessage({
                              id: `tableColumn.${TABLE_COLUMNS.RANGE}`,
                            })}
                          </Typography>
                        </TableCellHead>
                        {state.isEditMembers && (
                          <TableCellHead
                            compactVariant="dynamic"
                            size={!isMobile ? 'change' : undefined}
                          >
                            <Typography
                              align="center"
                              color='secondary'
                              noWrap
                              variant='caption'
                            >
                              {formatMessage({ id: 'actions' })}
                            </Typography>
                          </TableCellHead>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableRows.map((row: any) => (
                        <TableRow>
                          <TableCell>
                            <div className={classes.tableCell}>
                              <Typography
                                color={row.deleted ? 'secondary' : 'primary'}
                              >
                                {row[TABLE_COLUMNS.NAME].label}
                              </Typography>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className={classes.tableCell}>
                              {!state.isEditMembers && (
                                <Typography noWrap>
                                  {row[TABLE_COLUMNS.TITLE].label}
                                </Typography>
                              )}
                              {state.isEditMembers && (
                                <TextField
                                  disabled={row.deleted}
                                  fullWidth
                                  onChange={({ target }) =>
                                    onChangeMemberValue({
                                      columnName: TABLE_COLUMNS.TITLE,
                                      memberId: row.id,
                                      value: target.value,
                                    })}
                                  value={row[TABLE_COLUMNS.TITLE].value || ''}
                                  variant="standard"
                                />
                              )}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className={classes.tableCell}>
                              {!state.isEditMembers && (
                                <Typography>
                                  {row[TABLE_COLUMNS.PLANED_PERCENT].label}
                                </Typography>
                              )}
                              {state.isEditMembers && (
                                <div
                                  className={state
                                    .validationErrorsToRowIds[row.id]
                                    ?.includes(validationErrorTypes
                                      .PLANED_PERCENT_EXCEED_LIMITS)
                                    ? classes.planedPercentWithAdornment
                                    : classes.planedPercent
                                  }
                                >
                                  <TextField
                                    AdornmentEnd={(
                                      <>
                                        <Typography
                                          color={row.deleted
                                            ? 'secondary'
                                            : 'primary'}
                                        >
                                          %
                                        </Typography>
                                        {state
                                          .validationErrorsToRowIds[row.id]
                                          ?.includes(validationErrorTypes
                                            .PLANED_PERCENT_EXCEED_LIMITS)
                                          && (
                                            <Tooltip
                                              title={getValidationHint(
                                                validationErrorTypes
                                                  .PLANED_PERCENT_EXCEED_LIMITS
                                              )}
                                            >
                                              <IconError
                                                color="error"
                                                size={ICON_SIZE_CAPTION}
                                              />
                                            </Tooltip>
                                          )}
                                      </>
                                    )}
                                    disabled={row.deleted}
                                    isError={state
                                      .validationErrorsToRowIds[row.id]}
                                    onChange={({ target }) => {
                                      const strValue = target.value;
                                      if (
                                        strValue &&
                                        // eslint-disable-next-line max-len
                                        (strValue.length > PLANED_PERCENT_CHARS_LIMIT
                                          || !isNumber(strValue)
                                        )
                                      ) {
                                        return;
                                      }
                                      onChangeMemberValue({
                                        columnName: TABLE_COLUMNS
                                          .PLANED_PERCENT,
                                        memberId: row.id,
                                        value: strValue && +strValue,
                                      });
                                    }}
                                    value={row[TABLE_COLUMNS.PLANED_PERCENT]
                                      .value || null}
                                    variant="standard"
                                  />
                                </div>
                              )}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className={classes.tableCell}>
                              {!state.isEditMembers && (
                                <Typography>
                                  {row[TABLE_COLUMNS.TYPE].label}
                                </Typography>
                              )}
                              {state.isEditMembers && (
                                <Select
                                  disabled={row.deleted}
                                  fullWidth
                                  onChange={({ target }) =>
                                    onChangeMemberValue({
                                      columnName: TABLE_COLUMNS.TYPE,
                                      memberId: row.id,
                                      value: target.value,
                                    })}
                                  value={row[TABLE_COLUMNS.TYPE].value || null}
                                >
                                  <MenuItem value={null}>
                                    <Typography>
                                      {formatMessage({ id: 'NA' })}
                                    </Typography>
                                  </MenuItem>
                                  {rateTypesList.map((type: string) => (
                                    <MenuItem value={type}>
                                      <Typography>
                                        {formatMessage({
                                          id: `rateType.${type}`,
                                        })}
                                      </Typography>
                                    </MenuItem>
                                  ))}
                                </Select>
                              )}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className={classes.tableCell}>
                              {!state.isEditMembers && (
                                <Typography>
                                  {row[TABLE_COLUMNS.RATE].label}
                                </Typography>
                              )}
                              {state.isEditMembers && (
                                <div className={classes.rate}>
                                  <TextField
                                    AdornmentEnd={(
                                      <Select
                                        disabled={row.deleted}
                                        disableUnderline
                                        onChange={({ target }) =>
                                          onChangeMemberValue({
                                            columnName: TABLE_COLUMNS
                                              .RATE_CURRENCY,
                                            memberId: row.id,
                                            value: target.value,
                                          })}
                                        value={row[TABLE_COLUMNS
                                          .RATE_CURRENCY].value}
                                      >
                                        {orderedCurrencies
                                          .map((currency: any) => (
                                            <MenuItem value={currency}>
                                              <Typography>
                                                {currency}
                                              </Typography>
                                            </MenuItem>
                                          ))
                                        }
                                      </Select>
                                    )}
                                    disabled={row.deleted}
                                    fullWidth
                                    inputType="number"
                                    onChange={({ target }) => {
                                      onChangeMemberValue({
                                        columnName: TABLE_COLUMNS.RATE,
                                        memberId: row.id,
                                        value: target.value === ''
                                          ? ''
                                          : Number(target.value),
                                      });
                                    }}
                                    value={
                                      row[TABLE_COLUMNS.RATE].value === 0
                                        ? '0'
                                        : row[TABLE_COLUMNS.RATE].value || ''
                                    }
                                    variant="standard"
                                  />
                                </div>
                              )}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className={classes.tableCell}>
                              {!state.isEditMembers && (
                                <Typography noWrap>
                                  {row[TABLE_COLUMNS.RANGE].label}
                                </Typography>
                              )}
                              {state.isEditMembers && (
                                <>
                                  {row.deleted
                                    ? (
                                      <Typography
                                        noWrap
                                        color="secondary"
                                      >
                                        {row[TABLE_COLUMNS.RANGE].label}
                                      </Typography>
                                    )
                                    : (
                                      <Hover
                                        onClick={({ currentTarget }) =>
                                          onClickMemberRange({
                                            anchor: currentTarget,
                                            memberId: row.id,
                                          })}
                                      >
                                        <Typography noWrap>
                                          {row[TABLE_COLUMNS.RANGE].label}
                                        </Typography>
                                      </Hover>
                                    )}
                                </>
                              )}
                            </div>
                          </TableCell>
                          {state.isEditMembers && (
                            <TableCell>
                              <div className={classes.tableCellAction}>
                                {!row.deleted && (
                                  <>
                                    <Tooltip
                                      title={formatMessage({ id: 'duplicate' })}
                                    >
                                      <IconButton
                                        disableHoverSpace
                                        onClick={() =>
                                          onDuplicateMemberRow(row.id)}
                                      >
                                        <IconCopy size={ICON_SIZE} />
                                      </IconButton>
                                    </Tooltip>
                                    {getRowDeletability(row) && (
                                      <Tooltip
                                        title={formatMessage({ id: 'delete' })}
                                      >
                                        <IconButton
                                          disableHoverSpace
                                          onClick={() =>
                                            onDeleteRestoreMemberRow(row.id)}
                                        >
                                          <IconDelete size={ICON_SIZE} />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                  </>
                                )}
                                {row.deleted && (
                                  <Tooltip
                                    title={formatMessage({ id: 'restore' })}
                                  >
                                    <IconButton
                                      disableHoverSpace
                                      onClick={() =>
                                        onDeleteRestoreMemberRow(row.id)}
                                    >
                                      <IconRestore size={ICON_SIZE} />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </div>
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  {(!isMobile && state.isEditMembers) && (
                    <div className={classes.totalsContainer}>
                      {tableTotals.map((row: any, idx: number) => (
                        <div className={classes.totalsRowContainer}>
                          <div className={classes.totalsRowContainerLeft}>
                            {!idx && (
                              <Typography color="secondary">
                                {formatMessage({ id: 'total' })}
                              </Typography>
                            )}
                          </div>
                          <div className={classes.totalsRowContainerRight}>
                            <div
                              className={classes.totalsRowContainerRightInner}
                            >
                              <Typography color="secondary">
                                {formatMessage({
                                  id: `total.rateType.${row.type}`,
                                })}
                              </Typography>
                              <Typography color="secondary">
                                {`${row.value} ${row.currency}`}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  {state.isEditMembers && (
                    <CardActions>
                      <Button
                        onClick={onCancelEditMembers}
                        variant='secondary'
                      >
                        <Typography color='inherit'>
                          {formatMessage({ id: 'cancel' })}
                        </Typography>
                      </Button>
                      <Button
                        isLoading={isFetchingSaveProject}
                        onClick={onSave}
                        variant='primary'
                      >
                        <Typography color='inherit'>
                          {formatMessage({ id: 'save' })}
                        </Typography>
                      </Button>
                    </CardActions>
                  )}
                  <div/>
                </Card>
              </div>
            )}
            {state.tab === TABS.DETAILS && (
              <Card
                disablePaddings
                variant={state.isEditDetails ? 'edit' : 'paper'}
              >
                <div className={classes.detailsContainer}>
                  <CardTitle>
                    <Typography
                      color="secondary"
                      variant="title"
                    >
                      {formatMessage({ id: 'customerDetails' })}
                    </Typography>
                    {!state.isEditDetails && (
                      <IconButton
                        disableHoverSpace
                        onClick={() => setState({
                          ...state,
                          customerAddress: project.address,
                          customerAddressPol: project.addressPol,
                          customerBankAddress: project.bankAddress,
                          customerBankName: project.bankName,
                          customerBankNumber: project.bankAccountNumber,
                          customerCIF: project.cif,
                          customerEmail: project.emailPersonal,
                          customerKRS: project.krs,
                          customerNIP: project.nip,
                          customerName: project.name,
                          customerSignatory: project.signatory,
                          customerSwift: project.bankSwift,
                          customerTaxId: project.taxId,
                          generateInvoice: !!project.isNeedGenerateInvoice,
                          invoiceNumberPrefix: project.invoiceNumberPrefix,
                          isEditDetails: true,
                        })}
                      >
                        <IconEdit size={ICON_SIZE} />
                      </IconButton>
                    )}
                    {state.isEditDetails && (
                      <IconButton
                        disableHoverSpace
                        onClick={onCancelEditDetails}
                      >
                        <IconClose size={24} />
                      </IconButton>
                    )}
                  </CardTitle>
                  <Divider />
                  <CardContent>
                    {!state.isEditDetails && (
                      <div
                        className={isMobile
                          ? classes.infoContainerMobile
                          : classes.infoContainer}
                      >
                        <div
                          className={isMobile ? classes.rowMobile : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {clientRows[clientInfoFields.NAME].label}
                            </Typography>
                          </div>
                          <Typography wordBreak="break-word">
                            {clientRows[clientInfoFields.NAME].value}
                          </Typography>
                        </div>
                        <div
                          className={isMobile ? classes.rowMobile : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {clientRows[clientInfoFields.ADDRESS].label}
                            </Typography>
                          </div>
                          <Typography wordBreak="break-word">
                            {clientRows[clientInfoFields.ADDRESS].value}
                          </Typography>
                        </div>
                        <div
                          className={isMobile ? classes.rowMobile : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {clientRows[clientInfoFields.ADDRESS_POL].label}
                            </Typography>
                          </div>
                          <Typography wordBreak="break-word">
                            {clientRows[clientInfoFields.ADDRESS_POL].value}
                          </Typography>
                        </div>
                        <div
                          className={isMobile ? classes.rowMobile : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {clientRows[clientInfoFields.EMAIL].label}
                            </Typography>
                          </div>
                          <Typography wordBreak="break-word">
                            {clientRows[clientInfoFields.EMAIL].value}
                          </Typography>
                        </div>
                        <div
                          className={isMobile ? classes.rowMobile : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {clientRows[clientInfoFields.CIF].label}
                            </Typography>
                          </div>
                          <Typography wordBreak="break-word">
                            {clientRows[clientInfoFields.CIF].value}
                          </Typography>
                        </div>
                        <div
                          className={isMobile ? classes.rowMobile : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {clientRows[clientInfoFields.TAX_ID].label}
                            </Typography>
                          </div>
                          <Typography wordBreak="break-word">
                            {clientRows[clientInfoFields.TAX_ID].value}
                          </Typography>
                        </div>
                      </div>
                    )}
                    {state.isEditDetails && (
                      <div
                        className={isMobile
                          ? classes.infoContainerMobile
                          : classes.infoContainer}
                      >
                        <div
                          className={isMobile ? classes.rowMobile : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {clientRows[clientInfoFields.NAME].label}
                            </Typography>
                          </div>
                          <TextField
                            disabled={isFetchingUpdateDetails}
                            fullWidth
                            onChange={({ target }) => setState({
                              ...state,
                              customerName: target.value,
                            })}
                            size="small"
                            value={state.customerName}
                            variant="standard"
                          />
                        </div>
                        <div
                          className={isMobile ? classes.rowMobile : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {clientRows[clientInfoFields.ADDRESS].label}
                            </Typography>
                          </div>
                          <TextField
                            disabled={isFetchingUpdateDetails}
                            fullWidth
                            multiline
                            onChange={({ target }) => setState({
                              ...state,
                              customerAddress: target.value,
                            })}
                            size="small"
                            value={state.customerAddress}
                            variant="standard"
                          />
                        </div>
                        <div
                          className={isMobile ? classes.rowMobile : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {clientRows[clientInfoFields.ADDRESS_POL].label}
                            </Typography>
                          </div>
                          <TextField
                            disabled={isFetchingUpdateDetails}
                            fullWidth
                            multiline
                            onChange={({ target }) => setState({
                              ...state,
                              customerAddressPol: target.value,
                            })}
                            size="small"
                            value={state.customerAddressPol}
                            variant="standard"
                          />
                        </div>
                        <div
                          className={isMobile ? classes.rowMobile : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {clientRows[clientInfoFields.EMAIL].label}
                            </Typography>
                          </div>
                          <TextField
                            disabled={isFetchingUpdateDetails}
                            fullWidth
                            onChange={({ target }) => setState({
                              ...state,
                              customerEmail: target.value,
                            })}
                            size="small"
                            value={state.customerEmail}
                            variant="standard"
                          />
                        </div>
                        <div
                          className={isMobile ? classes.rowMobile : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {clientRows[clientInfoFields.CIF].label}
                            </Typography>
                          </div>
                          <TextField
                            disabled={isFetchingUpdateDetails}
                            fullWidth
                            onChange={({ target }) => setState({
                              ...state,
                              customerCIF: target.value,
                            })}
                            size="small"
                            value={state.customerCIF}
                            variant="standard"
                          />
                        </div>
                        <div
                          className={isMobile ? classes.rowMobile : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {clientRows[clientInfoFields.TAX_ID].label}
                            </Typography>
                          </div>
                          <TextField
                            disabled={isFetchingUpdateDetails}
                            fullWidth
                            onChange={({ target }) => setState({
                              ...state,
                              customerTaxId: target.value,
                            })}
                            size="small"
                            value={state.customerTaxId}
                            variant="standard"
                          />
                        </div>
                      </div>
                    )}
                  </CardContent>
                  <div />
                  {/* Bank Details */}
                  <CardTitle>
                    <Typography
                      color="secondary"
                      variant="title"
                    >
                      {formatMessage({ id: 'bankDetails' })}
                    </Typography>
                  </CardTitle>
                  <Divider />
                  <CardContent>
                    {!state.isEditDetails && (
                      <div
                        className={isMobile
                          ? classes.infoContainerMobile
                          : classes.infoContainer}
                      >
                        <div
                          className={isMobile ? classes.rowMobile : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {clientRows[clientInfoFields.BANK_NAME].label}
                            </Typography>
                          </div>
                          <Typography wordBreak="break-word">
                            {clientRows[clientInfoFields.BANK_NAME].value}
                          </Typography>
                        </div>
                        <div
                          className={isMobile ? classes.rowMobile : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {clientRows[clientInfoFields.BANK_ADDRESS].label}
                            </Typography>
                          </div>
                          <Typography wordBreak="break-word">
                            {clientRows[clientInfoFields.BANK_ADDRESS].value}
                          </Typography>
                        </div>
                        <div
                          className={isMobile ? classes.rowMobile : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {clientRows[clientInfoFields.KRS].label}
                            </Typography>
                          </div>
                          <Typography wordBreak="break-word">
                            {clientRows[clientInfoFields.KRS].value}
                          </Typography>
                        </div>
                        <div
                          className={isMobile ? classes.rowMobile : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {clientRows[clientInfoFields.NIP].label}
                            </Typography>
                          </div>
                          <Typography wordBreak="break-word">
                            {clientRows[clientInfoFields.NIP].value}
                          </Typography>
                        </div>
                        <div
                          className={isMobile ? classes.rowMobile : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {clientRows[clientInfoFields.SWIFT].label}
                            </Typography>
                          </div>
                          <Typography wordBreak="break-word">
                            {clientRows[clientInfoFields.SWIFT].value}
                          </Typography>
                        </div>
                        <div
                          className={isMobile ? classes.rowMobile : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {clientRows[clientInfoFields.BANK_NUMBER].label}
                            </Typography>
                          </div>
                          <Typography wordBreak="break-word">
                            {clientRows[clientInfoFields.BANK_NUMBER].value}
                          </Typography>
                        </div>
                      </div>
                    )}
                    {state.isEditDetails && (
                      <div
                        className={isMobile
                          ? classes.infoContainerMobile
                          : classes.infoContainer}
                      >
                        <div
                          className={isMobile ? classes.rowMobile : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {clientRows[clientInfoFields.BANK_NAME].label}
                            </Typography>
                          </div>
                          <TextField
                            disabled={isFetchingUpdateDetails}
                            fullWidth
                            onChange={({ target }) => setState({
                              ...state,
                              customerBankName: target.value,
                            })}
                            size="small"
                            value={state.customerBankName}
                            variant="standard"
                          />
                        </div>
                        <div
                          className={isMobile ? classes.rowMobile : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {clientRows[clientInfoFields.BANK_ADDRESS].label}
                            </Typography>
                          </div>
                          <TextField
                            disabled={isFetchingUpdateDetails}
                            multiline
                            fullWidth
                            onChange={({ target }) => setState({
                              ...state,
                              customerBankAddress: target.value,
                            })}
                            size="small"
                            value={state.customerBankAddress}
                            variant="standard"
                          />
                        </div>
                        <div
                          className={isMobile ? classes.rowMobile : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {clientRows[clientInfoFields.KRS].label}
                            </Typography>
                          </div>
                          <TextField
                            disabled={isFetchingUpdateDetails}
                            fullWidth
                            onChange={({ target }) => setState({
                              ...state,
                              customerKRS: target.value,
                            })}
                            size="small"
                            value={state.customerKRS}
                            variant="standard"
                          />
                        </div>
                        <div
                          className={isMobile ? classes.rowMobile : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {clientRows[clientInfoFields.NIP].label}
                            </Typography>
                          </div>
                          <TextField
                            disabled={isFetchingUpdateDetails}
                            fullWidth
                            onChange={({ target }) => setState({
                              ...state,
                              customerNIP: target.value,
                            })}
                            size="small"
                            value={state.customerNIP}
                            variant="standard"
                          />
                        </div>
                        <div
                          className={isMobile ? classes.rowMobile : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {clientRows[clientInfoFields.SWIFT].label}
                            </Typography>
                          </div>
                          <TextField
                            disabled={isFetchingUpdateDetails}
                            fullWidth
                            onChange={({ target }) => setState({
                              ...state,
                              customerSwift: target.value,
                            })}
                            size="small"
                            value={state.customerSwift}
                            variant="standard"
                          />
                        </div>
                        <div
                          className={isMobile ? classes.rowMobile : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {clientRows[clientInfoFields.BANK_NUMBER].label}
                            </Typography>
                          </div>
                          <TextField
                            disabled={isFetchingUpdateDetails}
                            fullWidth
                            onChange={({ target }) => setState({
                              ...state,
                              customerBankNumber: target.value,
                            })}
                            size="small"
                            value={state.customerBankNumber}
                            variant="standard"
                          />
                        </div>
                      </div>
                    )}
                  </CardContent>
                  <div />
                  {/* Invoice Details */}
                  <CardTitle>
                    <Typography
                      color="secondary"
                      variant="title"
                    >
                      {formatMessage({ id: 'invoiceDetails' })}
                    </Typography>
                  </CardTitle>
                  <Divider />
                  <CardContent>
                    {!state.isEditDetails && (
                      <div
                        className={isMobile
                          ? classes.infoContainerMobile
                          : classes.infoContainer}
                      >
                        <div
                          className={isMobile ? classes.rowMobile : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {clientRows[clientInfoFields.SIGNATORY].label}
                            </Typography>
                          </div>
                          <Typography wordBreak="break-word">
                            {clientRows[clientInfoFields.SIGNATORY].value}
                          </Typography>
                        </div>
                        <div
                          className={isMobile ? classes.rowMobile : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {clientRows[clientInfoFields.GENERATE_INVOICE]
                                .label}
                            </Typography>
                          </div>
                          <Typography>
                            {formatMessage({
                              id: clientRows[clientInfoFields.GENERATE_INVOICE]
                                .value
                                ? 'client.GENERATE_INVOICE.yes'
                                : 'client.GENERATE_INVOICE.no',
                            })}
                          </Typography>
                        </div>
                        <div
                          className={isMobile ? classes.rowMobile : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {clientRows[clientInfoFields.INVOICE_PREFIX]
                                .label}
                            </Typography>
                          </div>
                          <Typography>
                            {clientRows[clientInfoFields.INVOICE_PREFIX].value}
                          </Typography>
                        </div>
                      </div>
                    )}
                    {state.isEditDetails && (
                      <div
                        className={isMobile
                          ? classes.infoContainerMobile
                          : classes.infoContainer}
                      >
                        <div
                          className={isMobile ? classes.rowMobile : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {clientRows[clientInfoFields.SIGNATORY].label}
                            </Typography>
                          </div>
                          <TextField
                            disabled={isFetchingUpdateDetails}
                            fullWidth
                            onChange={({ target }) => setState({
                              ...state,
                              customerSignatory: target.value,
                            })}
                            size="small"
                            value={state.customerSignatory}
                            variant="standard"
                          />
                        </div>
                        <div
                          className={isMobile ? classes.rowMobile : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {clientRows[clientInfoFields.GENERATE_INVOICE]
                                .label}
                            </Typography>
                          </div>
                          <Select
                            fullWidth
                            onChange={({ target }) => setState({
                              ...state,
                              generateInvoice: target.value,
                            })}
                            size="small"
                            value={state.generateInvoice}
                            variant="outlined"
                          >
                            <MenuItem value={true}>
                              <Typography>
                                {formatMessage({
                                  id: 'client.GENERATE_INVOICE.yes',
                                })}
                              </Typography>
                            </MenuItem>
                            <MenuItem value={false}>
                              <Typography>
                                {formatMessage({
                                  id: 'client.GENERATE_INVOICE.no',
                                })}
                              </Typography>
                            </MenuItem>
                          </Select>
                        </div>
                        <div
                          className={isMobile ? classes.rowMobile : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {clientRows[clientInfoFields.INVOICE_PREFIX]
                                .label}
                            </Typography>
                          </div>
                          <TextField
                            AdornmentEnd={
                              (state.detailsTabValidationErrors
                                .includes(validationErrorTypes
                                  .INVOICE_PREFIX_EMPTY))
                              && (
                                <Tooltip
                                  arrow
                                  placement="top"
                                  title={getValidationHint(validationErrorTypes
                                    .INVOICE_PREFIX_EMPTY)}
                                >
                                  <IconError
                                    color={theme.colors.redDark}
                                    size={ICON_SIZE}
                                  />
                                </Tooltip>
                              )
                            }
                            disabled={isFetchingUpdateDetails}
                            fullWidth
                            onChange={({ target }) => {
                              const slicedPrefix = target.value
                                .slice(0, INVOICE_PREFIX_CHARS_LIMIT);
                              setState({
                                ...state,
                                invoiceNumberPrefix: slicedPrefix,
                              });
                            }}
                            size="small"
                            value={state.invoiceNumberPrefix}
                            variant="standard"
                          />
                        </div>
                      </div>
                    )}
                  </CardContent>
                  <div />
                  {/* Actions */}
                  {state.isEditDetails && (
                    <CardActions>
                      <Button
                        onClick={onCancelEditDetails}
                        variant="secondary"
                      >
                        <Typography color="inherit">
                          {formatMessage({ id: 'cancel' })}
                        </Typography>
                      </Button>
                      <Button
                        isLoading={isFetchingUpdateDetails}
                        onClick={onDetailsTabSave}
                        variant="primary"
                      >
                        <Typography color="inherit">
                          {formatMessage({ id: 'save' })}
                        </Typography>
                      </Button>
                    </CardActions>
                  )}
                </div>
              </Card>
            )}
          </div>
        )}

      {/* PERIODICITY POPOVER */}
      <Popover
        anchorEl={state.periodicityAnchor}
        open={!!state.editMemberPeriodicityId}
        onClose={onClosePeriodicityPopover}
      >
        <Card variant="edit">
          <CardTitle>
            <Typography variant="title">
              {popoverMember?.name}
            </Typography>
          </CardTitle>
          <div />
          <CardContent>
            <div className={classes.popoverContent}>
              <div className={classes.rowMobile}>
                <div className={classes.row}>
                  <div className={classes.popoverCell}>
                    <Typography>
                      {formatMessage({
                        id: 'popover.PERIODICITY.from',
                      })}
                    </Typography>
                  </div>
                  <div className={classes.popoverPeriodicity}>
                    <div className={classes.selectMonth}>
                      <Select
                        disabled={isFetchingSaveProject}
                        fullWidth
                        onChange={({ target }) => onChangeMemberValue({
                          columnName: TABLE_COLUMNS.RANGE,
                          memberId: popoverMember.id,
                          value: {
                            fromMonth: target.value,
                            fromYear: popoverMember.range?.fromYear,
                            toMonth: popoverMember.range?.toMonth,
                            toYear: popoverMember.range?.toYear,
                          },
                        })}
                        size="small"
                        value={popoverMember?.range?.fromMonth}
                      >
                        <MenuItem value={null}>
                          <Typography>
                            {formatMessage({ id: 'NA' })}
                          </Typography>
                        </MenuItem>
                        {months.map((month) => (
                          <MenuItem value={month}>
                            <Typography>
                              {formatMessage({ id: `month.${month}` })}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className={classes.selectYear}>
                      <Select
                        disabled={isFetchingSaveProject}
                        fullWidth
                        onChange={({ target }) => onChangeMemberValue({
                          columnName: TABLE_COLUMNS.RANGE,
                          memberId: popoverMember.id,
                          value: {
                            fromMonth: popoverMember.range?.fromMonth,
                            fromYear: target.value,
                            toMonth: popoverMember.range?.toMonth,
                            toYear: popoverMember.range?.toYear,
                          },
                        })}
                        size="small"
                        value={popoverMember?.range?.fromYear}
                      >
                        <MenuItem value={null}>
                          <Typography>
                            {formatMessage({ id: 'NA' })}
                          </Typography>
                        </MenuItem>
                        {years.map(year => (
                          <MenuItem value={year}>
                            <Typography>
                              {year}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
                <div className={classes.row}>
                  <div className={classes.popoverCell}>
                    <Typography>
                      {formatMessage({
                        id: 'popover.PERIODICITY.to',
                      })}
                    </Typography>
                  </div>
                  <div className={classes.popoverPeriodicity}>
                    <div className={classes.selectMonth}>
                      <Select
                        disabled={isFetchingSaveProject}
                        fullWidth
                        onChange={({ target }) => onChangeMemberValue({
                          columnName: TABLE_COLUMNS.RANGE,
                          memberId: popoverMember.id,
                          value: {
                            fromMonth: popoverMember.range?.fromMonth,
                            fromYear: popoverMember.range?.fromYear,
                            toMonth: target.value,
                            toYear: popoverMember.range?.toYear,
                          },
                        })}
                        size="small"
                        value={popoverMember?.range?.toMonth}
                      >
                        <MenuItem value={null}>
                          <Typography>
                            {formatMessage({ id: 'NA' })}
                          </Typography>
                        </MenuItem>
                        {months.map((month) => (
                          <MenuItem value={month}>
                            <Typography>
                              {formatMessage({ id: `month.${month}` })}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className={classes.selectYear}>
                      <Select
                        disabled={isFetchingSaveProject}
                        fullWidth
                        onChange={({ target }) => onChangeMemberValue({
                          columnName: TABLE_COLUMNS.RANGE,
                          memberId: popoverMember.id,
                          value: {
                            fromMonth: popoverMember.range?.fromMonth,
                            fromYear: popoverMember.range?.fromYear,
                            toMonth: popoverMember.range?.toMonth,
                            toYear: target.value,
                          },
                        })}
                        size="small"
                        value={popoverMember?.range?.toYear}
                      >
                        <MenuItem value={null}>
                          <Typography>
                            {formatMessage({ id: 'NA' })}
                          </Typography>
                        </MenuItem>
                        {years.map(year => (
                          <MenuItem value={year}>
                            <Typography>
                              {year}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.rowMobile}>
                <Typography
                  color="secondary"
                  variant="subtitle"
                >
                  {getPeriodicityLabel({
                    fromMonth: popoverMember?.range?.fromMonth,
                    fromYear: popoverMember?.range?.fromYear,
                    toMonth: popoverMember?.range?.toMonth,
                    toYear: popoverMember?.range?.toYear,
                    withDuration: true,
                  })}
                </Typography>
              </div>
            </div>
          </CardContent>
        </Card>
      </Popover>

      {/* ALERTS */}
      <Snackbar
        autoHide
        onClose={onCloseAlert}
        open={state.showAfterSaveAlert
          && (isSuccessSaveProject || isSuccessUpdateDetails)}
      >
        <Card variant="success">
          <CardTitle>
            <Typography color="success">
              {formatMessage({ id: 'save.success' })}
            </Typography>
          </CardTitle>
        </Card>
      </Snackbar>
      <Snackbar
        onClose={(event: any, reason: string) => {
          if (reason !== 'clickaway') {
            onCloseAlert();
          }
        }}
        open={state.showAfterSaveAlert
          && (isFailedSaveProject || isFailedUpdateDetails)}
      >
        <Card variant="error">
          <CardTitle>
            <Typography color="error">
              {errorMessage
                ? formatMessage(
                  { id: 'error' },
                  { value: errorMessage }
                )
                : formatMessage({ id: 'save.error' })}
            </Typography>
            <IconButton
              disableHoverSpace
              onClick={onCloseAlert}
            >
              <IconClose size={24}/>
            </IconButton>
          </CardTitle>
        </Card>
      </Snackbar>
    </div>
  );
}

export default ProjectMembersAndRates;
