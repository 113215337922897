export const REQUEST_CONTRACTOR = 'REQUEST_CONTRACTOR';
export const RECEIVE_CONTRACTOR = 'RECEIVE_CONTRACTOR';
export const ERROR_RECEIVE_CONTRACTOR = 'ERROR_RECEIVE_CONTRACTOR';

export const REQUEST_COMPENSATIONS = 'REQUEST_COMPENSATIONS';
export const RECEIVE_COMPENSATIONS = 'RECEIVE_COMPENSATIONS';
export const ERROR_RECEIVE_COMPENSATIONS = 'ERROR_RECEIVE_COMPENSATIONS';

export const REQUEST_UPDATE_CONTRACTOR = 'REQUEST_UPDATE_CONTRACTOR';
export const SUCCESS_UPDATE_CONTRACTOR = 'SUCCESS_UPDATE_CONTRACTOR';
export const ERROR_UPDATE_CONTRACTOR = 'ERROR_UPDATE_CONTRACTOR';

export const REQUEST_CREATE_CONTRACTOR = 'REQUEST_CREATE_CONTRACTOR';
export const SUCCESS_CREATE_CONTRACTOR = 'SUCCESS_CREATE_CONTRACTOR';
export const ERROR_CREATE_CONTRACTOR = 'ERROR_CREATE_CONTRACTOR';

export const REQUEST_UPDATE_COMPENSATION = 'REQUEST_UPDATE_COMPENSATION';
export const SUCCESS_UPDATE_COMPENSATION = 'SUCCESS_UPDATE_COMPENSATION';
export const ERROR_UPDATE_COMPENSATION = 'ERROR_UPDATE_COMPENSATION';

export const REQUEST_CREATE_COMPENSATION = 'REQUEST_CREATE_COMPENSATION';
export const SUCCESS_CREATE_COMPENSATION = 'SUCCESS_CREATE_COMPENSATION';
export const ERROR_CREATE_COMPENSATION = 'ERROR_CREATE_COMPENSATION';

export const REQUEST_DELETE_COMPENSATION = 'REQUEST_DELETE_COMPENSATION';
export const SUCCESS_DELETE_COMPENSATION = 'SUCCESS_DELETE_COMPENSATION';
export const ERROR_DELETE_COMPENSATION = 'ERROR_DELETE_COMPENSATION';

export const REQUEST_TYPES = 'REQUEST_TYPES';
export const RECEIVE_TYPES = 'RECEIVE_TYPES';
export const ERROR_RECEIVE_TYPES = 'ERROR_RECEIVE_TYPES';

export const REQUEST_CONTRACT_TYPES = 'REQUEST_CONTRACT_TYPES';
export const RECEIVE_CONTRACT_TYPES = 'RECEIVE_CONTRACT_TYPES';
export const ERROR_RECEIVE_CONTRACT_TYPES = 'ERROR_RECEIVE_CONTRACT_TYPES';

export const RESET_REDUCERS_DATA = 'RESET_REDUCERS_DATA';
