import { combineReducers } from 'redux';

import contractTypes from './contractTypes';
import invoiceForms from './invoiceForms';
import registryForms from './registryForms';
import taxTypes from './taxTypes';

const rootReducer = combineReducers({
  contractTypes,
  invoiceForms,
  registryForms,
  taxTypes,
});

export default rootReducer;
