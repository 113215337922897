import { combineReducers } from 'redux';

import payrolls from './payrolls';
import reports from './reports';
import contractTypes from './contractTypes';

const rootReducer = combineReducers({
  contractTypes,
  payrolls,
  reports,
});

export default rootReducer;
