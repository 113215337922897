import {
  ERROR_RECEIVE_INVOICE_FORMS,
  RECEIVE_INVOICE_FORMS,
  REQUEST_INVOICE_FORMS,
} from '../constants/actionTypes';

import axios from 'utils/requests';
import config from 'config';

const requestInvoiceForms = () => ({
  type: REQUEST_INVOICE_FORMS,
});

const receiveInvoiceForms = (response: any) => ({
  payload: response,
  type: RECEIVE_INVOICE_FORMS,
});

const errorReceiveInvoiceForms = () => ({
  type: ERROR_RECEIVE_INVOICE_FORMS,
});

const getInvoiceForms = () => {
  const {
    BASE_URL,
    CONTRACT_TYPE_SERVICE,
  } = config;

  return axios
    .get(`${BASE_URL}${CONTRACT_TYPE_SERVICE}/invoice-forms`)
    .then(({ data }) => data);
};

const fetchInvoiceForms = () => (dispatch: any) => {
  dispatch(requestInvoiceForms());

  return getInvoiceForms()
    .then(forms => dispatch(receiveInvoiceForms(forms)))
    .catch(() => dispatch(errorReceiveInvoiceForms()));
};

const exportFunctions = {
  fetchInvoiceForms,
};

export default exportFunctions;
