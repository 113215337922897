import PageContainer from '../components/PageContainer';
import PageAccessValidator from '../components/PageAccessValidator';
import authoritiesUI from '../constants/authoritiesUI';
import TaxTypesPage from '../pages/taxTypes';

function TaxTypes() {
  return (
    <PageContainer>
      <PageAccessValidator neededAuthorities={authoritiesUI.SETTINGS_FINANCES}>
        <TaxTypesPage />
      </PageAccessValidator>
    </PageContainer>
  );
}

export default TaxTypes;
