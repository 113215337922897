import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { useIntl } from 'react-intl';
import Breadcrumb from 'components/Breadcrumb';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Card from 'components/Card';
import CardContent from 'components/CardContent';
import CardTitle from 'components/CardTitle';
import IconMoney from 'components/icons/Money';
import IconPerson from 'components/icons/Person';
import IconWidget from 'components/icons/Widget';
import Link from 'components/Link';
import Typography from 'components/Typography';
import useIsMobile from 'hooks/useIsMobile';
import useTheme from 'hooks/useTheme';

import * as pages from 'constants/pages';
import pagesURLs from 'constants/pagesURLs';
import authoritiesUI from 'constants/authoritiesUI';
import AccessValidator from 'components/AccessValidator';

const getClasses = makeStyles<any>()((_, theme: any) => ({
  cardContent: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: `${theme.spacing(2)}px`,
  },
  cardContentMobile: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: `${theme.spacing(2)}px`,
    padding: `0px ${theme.spacing(0.5)}px`,
  },
  cardTitle: {
    alignItems: 'center',
    display: 'flex',
    gap: `${theme.spacing(1)}px`,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(2)}px`,
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(1)}px`,
    width: '30%',
  },
  itemMobile: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(0.5)}px`,
  },
  link: {
    color: theme.link.color,
  },
}));

const settingsGroups = [
  {
    icon: <IconMoney />,
    items: [
      {
        link: `${pagesURLs[pages.currencyRates]}`,
        titleIntlId: 'currencyRates',
      },
      {
        link: `${pagesURLs[pages.compensationTypes]}`,
        titleIntlId: 'compensationTypes',
      },
      {
        link: `${pagesURLs[pages.costItems]}`,
        titleIntlId: 'costItems',
      },
      {
        link: `${pagesURLs[pages.taxTypes]}`,
        titleIntlId: 'taxTypes',
      },
      {
        link: `${pagesURLs[pages.taxes]}`,
        titleIntlId: 'taxes',
      },
      {
        link: `${pagesURLs[pages.contractTypes]}`,
        titleIntlId: 'contractTypes',
      },
    ],
    neededAuthorities: [authoritiesUI.SETTINGS_FINANCES],
    titleIntlId: 'finances',
  },
  {
    icon: <IconPerson />,
    items: [
      {
        link: `${pagesURLs[pages.userManagement]}`,
        titleIntlId: 'userManagement',
      },
    ],
    neededAuthorities: [authoritiesUI.SETTINGS_USERS],
    titleIntlId: 'users',
  },
  {
    icon: <IconWidget />,
    items: [
      {
        link: `${pagesURLs[pages.commonSettings]}`,
        titleIntlId: 'commonSettings',
      },
    ],
    neededAuthorities: [authoritiesUI.COMMON_SETTINGS],
    titleIntlId: 'app',
  },
];

function Settings() {
  const { theme } = useTheme();
  const { classes } = getClasses(theme);
  const { formatMessage } = useIntl();
  const isMobile = useIsMobile();

  return (
    <div className={classes.container}>
      <Breadcrumbs>
        <Breadcrumb
          label={formatMessage({ id: 'settings' })}
          variant="text"
        />
      </Breadcrumbs>
      {settingsGroups.map(group => (
        <AccessValidator
          neededAuthorities={group.neededAuthorities}>
          <Card>
            <CardTitle>
              <div className={classes.cardTitle}>
                {group.icon}
                <Typography variant="title">
                  {formatMessage({
                    id: group.titleIntlId,
                  })}
                </Typography>
              </div>
            </CardTitle>
            <CardContent>
              <div
                className={isMobile
                  ? classes.cardContentMobile
                  : classes.cardContent}
              >
                {group.items.map(item => (
                  <div
                    className={isMobile ? classes.itemMobile : classes.item}
                  >
                    <Link to={{ pathname: item.link }}>
                      <div className={classes.link}>
                        <Typography
                          color="inherit"
                          variant="subtitle"
                        >
                          <strong>
                            {formatMessage({
                              id: item.titleIntlId,
                            })}
                          </strong>
                        </Typography>
                      </div>
                    </Link>
                    <Typography
                      color="secondary"
                      variant="caption"
                    >
                      {formatMessage({
                        id: `${item.titleIntlId}.description`,
                      })}
                    </Typography>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        </AccessValidator>
      ))}
    </div>
  );
}

export default Settings;
