import React from 'react';
import authoritiesUI from 'constants/authoritiesUI';
import TaxesPage from 'pages/taxes';
import PageContainer from 'components/PageContainer';
import PageAccessValidator from 'components/PageAccessValidator';

function Taxes() {
  return (
    <PageContainer>
      <PageAccessValidator neededAuthorities={authoritiesUI.SETTINGS_FINANCES}>
        <TaxesPage />
      </PageAccessValidator>
    </PageContainer>
  );
}

export default Taxes;
