import {
  ERROR_RECEIVE_INVOICE_FORMS,
  RECEIVE_INVOICE_FORMS,
  REQUEST_INVOICE_FORMS,
  RESET_REDUCERS_DATA,
} from '../constants/actionTypes';

type Action = {
  payload: any,
  type: string,
}

const initialState = {
  isFailed: false,
  isFetching: false,
  list: [],
};

const convertInvoiceForms = (forms: any) => (forms || [])
  .map((form: any) => ({
    id: form.code,
    name: form.name,
  }));

export default function Reducer(state = initialState, action: Action) {
  switch (action.type) {
    case REQUEST_INVOICE_FORMS: {
      return {
        ...state,
        isFailed: false,
        isFetching: true,
      };
    }

    case RECEIVE_INVOICE_FORMS: {
      const invoiceForms = action.payload;
      const list = convertInvoiceForms(invoiceForms);

      return {
        ...state,
        isFailed: false,
        isFetching: false,
        list: list,
      };
    }

    case ERROR_RECEIVE_INVOICE_FORMS: {
      return {
        ...state,
        isFailed: true,
        isFetching: false,
      };
    }

    case RESET_REDUCERS_DATA: return initialState;

    default: return state;
  }
}
