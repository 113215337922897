import axios from 'axios';
import config from 'config';
import {
  ERROR_RECEIVE_TAXES,
  ERROR_SAVE_TAXES,
  RECEIVE_TAXES,
  REQUEST_SAVE_TAXES,
  REQUEST_TAXES,
  SUCCESS_SAVE_TAXES,
} from '../constants/actionTypes';

const requestTaxes = () => ({
  type: REQUEST_TAXES,
});

const receiveTaxes = (response: any) => ({
  payload: response,
  type: RECEIVE_TAXES,
});

const errorReceiveTaxes = () => ({
  type: ERROR_RECEIVE_TAXES,
});

const getTaxesPerYear = (year: any) => {
  const {
    BASE_URL,
    TAX_SERVICE,
  } = config;

  return axios
    .get(`${BASE_URL}${TAX_SERVICE}/period/${year}`)
    .then(({ data }) => data);
};

const fetchTaxes = (year: any) => (dispatch: any) => {
  dispatch(requestTaxes());
  return getTaxesPerYear(year)
    .then(taxes => dispatch(receiveTaxes(taxes)))
    .catch(() => dispatch(errorReceiveTaxes()));
};

const requestSaveTaxes = () => ({
  type: REQUEST_SAVE_TAXES,
});

const successSaveTaxes = (response: any) => ({
  payload: response,
  type: SUCCESS_SAVE_TAXES,
});

const errorSaveTaxes = () => ({
  type: ERROR_SAVE_TAXES,
});

const bulkUpdateTaxes = (list: any) => {
  const {
    BASE_URL,
    TAX_SERVICE,
  } = config;
  return axios
    .post(
      `${BASE_URL}${TAX_SERVICE}/bulkUpdate`,
      list
    )
    .then(({ data }) => data);
};

const fetchSaveTax = ({
  saveList,
  year,
}: any) => (dispatch: any) => {
  dispatch(requestSaveTaxes());
  return bulkUpdateTaxes(saveList)
    .then(() => {
      return getTaxesPerYear(year)
        .then((taxes) => dispatch(successSaveTaxes(taxes)))
        .catch(() => dispatch(errorReceiveTaxes()));
    })
    .catch(() => dispatch(errorSaveTaxes()));
};

const exportFunctions = {
  fetchSaveTax,
  fetchTaxes,
};

export default exportFunctions;

