import {
  ERROR_RECEIVE_TAX_TYPES,
  RECEIVE_TAX_TYPES,
  REQUEST_TAX_TYPES,
} from '../constants/actionTypes';

import axios from 'utils/requests';
import config from 'config';

const requestTaxTypes = () => ({
  type: REQUEST_TAX_TYPES,
});

const receiveTaxTypes = (response: any) => ({
  payload: response,
  type: RECEIVE_TAX_TYPES,
});

const errorReceiveTaxTypes = () => ({
  type: ERROR_RECEIVE_TAX_TYPES,
});

const getTaxTypes = (activeOnly: boolean = false) => {
  const {
    BASE_URL,
    TAX_TYPE_SERVICE,
  } = config;

  return axios
    .get(
      `${BASE_URL}${TAX_TYPE_SERVICE}`,
      { params: { activeOnly } }
    )
    .then(({ data }) => data);
};

const fetchTaxTypes = () => (disptach: any) => {
  disptach(requestTaxTypes());

  return getTaxTypes()
    .then(taxTypes => disptach(receiveTaxTypes(taxTypes)))
    .catch(() => disptach(errorReceiveTaxTypes()));
};

const exportFunctions = {
  fetchTaxTypes,
};

export default exportFunctions;
