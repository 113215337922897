import { combineReducers } from 'redux';

import tax from './taxes';
import taxTypes from './taxTypes';

const rootReducer = combineReducers({
  tax,
  taxTypes,
});

export default rootReducer;
