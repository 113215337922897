import {
  ERROR_RECEIVE_CONTRACT_TYPES,
  RECEIVE_CONTRACT_TYPES,
  REQUEST_CONTRACT_TYPES,
} from '../constants/actionTypes';

import axios from 'utils/requests';
import config from 'config';

const requestContractTypes = () => ({
  type: REQUEST_CONTRACT_TYPES,
});

const receiveContractTypes = (response: any) => ({
  payload: response,
  type: RECEIVE_CONTRACT_TYPES,
});

const errorReceiveContractTypes = () => ({
  type: ERROR_RECEIVE_CONTRACT_TYPES,
});

const getContractTypes = (activeOnly: boolean = false) => {
  const {
    BASE_URL,
    CONTRACT_TYPE_SERVICE,
  } = config;

  return axios
    .get(`${BASE_URL}${CONTRACT_TYPE_SERVICE}`, {
      params: { activeOnly },
    })
    .then(({ data }) => data);
};

const fetchContractTypes = (activeOnly: boolean = false) => (dispatch: any) => {
  dispatch(requestContractTypes());
  return getContractTypes(activeOnly)
    .then(types => dispatch(receiveContractTypes(types)))
    .catch(() => dispatch(errorReceiveContractTypes()));
};

const exportFunctions = {
  fetchContractTypes,
};

export default exportFunctions;
