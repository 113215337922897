import { RESET_REDUCERS_DATA } from '../constants/actionTypes';

const resetReducersData = () => (dispatch: any) => dispatch({
  type: RESET_REDUCERS_DATA,
});

const exportFunctions = {
  resetReducersData,
};

export default exportFunctions;
