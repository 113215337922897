/* eslint-disable */
export const REQUEST_PAYROLLS = 'REQUEST_PAYROLLS';
export const RECEIVE_PAYROLLS = 'RECEIVE_PAYROLLS';
export const ERROR_RECEIVE_PAYROLLS = 'ERROR_RECEIVE_PAYROLLS';

export const REQUEST_PAYROLL = 'REQUEST_PAYROLL';
export const RECEIVE_PAYROLL = 'RECEIVE_PAYROLL';
export const ERROR_RECEIVE_PAYROLL = 'ERROR_RECEIVE_PAYROLL';

export const REQUEST_REFRESH_PAYROLLS = 'REQUEST_REFRESH_PAYROLLS';
export const SUCCESS_REFRESH_PAYROLLS = 'SUCCESS_REFRESH_PAYROLLS';
export const ERROR_REFRESH_PAYROLLS = 'ERROR_REFRESH_PAYROLLS';

export const REQUEST_UPDATE_PAYROLL_STATUS = 'REQUEST_UPDATE_PAYROLL_STATUS';
export const SUCCESS_UPDATE_PAYROLL_STATUS = 'SUCCESS_UPDATE_PAYROLL_STATUS';
export const ERROR_UPDATE_PAYROLL_STATUS = 'ERROR_UPDATE_PAYROLL_STATUS';

export const REQUEST_PAYROLL_ITEM = 'REQUEST_PAYROLL_ITEM';
export const RECEIVE_PAYROLL_ITEM = 'RECEIVE_PAYROLL_ITEM';
export const ERROR_RECEIVE_PAYROLL_ITEM = 'ERROR_RECEIVE_PAYROLL_ITEM';

export const REQUEST_UPDATE_PAYROLL_ITEM = 'REQUEST_UPDATE_PAYROLL_ITEM';
export const SUCCESS_UPDATE_PAYROLL_ITEM = 'SUCCESS_UPDATE_PAYROLL_ITEM';
export const ERROR_UPDATE_PAYROLL_ITEM = 'ERROR_UPDATE_PAYROLL_ITEM';

export const REQUEST_REFRESH_PAYROLL = 'REQUEST_REFRESH_PAYROLL';
export const SUCCESS_REFRESH_PAYROLL = 'SUCCESS_REFRESH_PAYROLL';
export const ERROR_REFRESH_PAYROLL = 'ERROR_REFRESH_PAYROLL';

export const REQUEST_DOWNLOAD_INVOICE = 'REQUEST_DOWNLOAD_INVOICE';
export const SUCCESS_DOWNLOAD_INVOICE = 'SUCCESS_DOWNLOAD_INVOICE';
export const ERROR_DOWNLOAD_INVOICE = 'ERROR_DOWNLOAD_INVOICE';

export const REQUEST_DOWNLOAD_REGISTRY = 'REQUEST_DOWNLOAD_REGISTRY';
export const SUCCESS_DOWNLOAD_REGISTRY = 'SUCCESS_DOWNLOAD_REGISTRY';
export const ERROR_DOWNLOAD_REGISTRY = 'ERROR_DOWNLOAD_REGISTRY';

export const REQUEST_SEND_INVOICE = 'REQUEST_SEND_INVOICE';
export const SUCCESS_SEND_INVOICE = 'SUCCESS_SEND_INVOICE';
export const ERROR_SEND_INVOICE = 'ERROR_SEND_INVOICE';

export const REQUEST_PAYROLL_CHANGE_HISTORY = 'REQUEST_PAYROLL_CHANGE_HISTORY';
export const RECEIVE_PAYROLL_CHANGE_HISTORY = 'RECEIVE_PAYROLL_CHANGE_HISTORY';
export const ERROR_RECEIVE_PAYROLL_CHANGE_HISTORY = 'ERROR_RECEIVE_PAYROLL_CHANGE_HISTORY';

export const REQUEST_SEND_CONFIRMED_INVOICES = 'REQUEST_SEND_CONFIRMED_INVOICE';
export const SUCCESS_SEND_CONFIRMED_INVOICES = 'SUCCESS_SEND_CONFIRMED_INVOICES';
export const ERROR_SEND_CONFIRMED_INVOICES = 'ERROR_SEND_CONFIRMED_INVOICE';

export const REQUEST_SENT_INVOICES = 'REQUEST_SENT_INVOICES';
export const RECEIVE_SENT_INVOICES = 'RECEIVE_SENT_INVOICES';
export const SUCCESS_RECEIVE_SENT_INVOICES = 'SUCCESS_RECEIVE_SENT_INVOICES';
export const ERROR_RECEIVE_SENT_INVOICES = 'ERROR_RECEIVE_SENT_INVOICES';

export const REQUEST_DELETE_PAYROLL = 'REQUEST_DELETE_PAYROLL';
export const SUCCESS_DELETE_PAYROLL = 'SUCCESS_DELETE_PAYROLL';
export const ERROR_DELETE_PAYROLL = 'ERROR_DELETE_PAYROLL';

export const REQUEST_CONTRACT_TYPES = 'REQUEST_CONTRACT_TYPES';
export const RECEIVE_CONTRACT_TYPES = 'RECEIVE_CONTRACT_TYPES';
export const ERROR_RECEIVE_CONTRACT_TYPES = 'ERROR_RECEIVE_CONTRACT_TYPES';

export const CLEAR_AFTER_FETCH_STATUS = 'CLEAR_AFTER_FETCH_STATUS';
export const RESET_REDUCERS_DATA = 'RESET_REDUCERS_DATA';
